export const GLOBAL_BASE_URL = process.env.VUE_APP_API_BASE_URL

//===========================================store function api=========================================
export const EP_GET_COUNTRIES_LIST = `${GLOBAL_BASE_URL}/client_country_list.js`
export const EP_USER_LOGIN = `${GLOBAL_BASE_URL}/api/login`
export const EP_REFRESH_DATA = `${GLOBAL_BASE_URL}/get/fresh/user/data`
export const EP_USER_LOGIN_2FA = `${GLOBAL_BASE_URL}/api/login/twofa`
export const EP_REGISTRATION = `${GLOBAL_BASE_URL}/api/create/lead`
export const EP_VERIFY_REGISTRATION = `${GLOBAL_BASE_URL}/api/verify/otp/lead`
export const EP_USER_FORGOT_PASSWORD = `${GLOBAL_BASE_URL}/forgot/password`
export const EP_ADMIN_LOGOUT = `${GLOBAL_BASE_URL}/signout`
export const EP_GENERATE_2FA_QRCODE = `${GLOBAL_BASE_URL}/generate/twofa/qrcode`
export const EP_VERIFY_2FA_QRCODE = `${GLOBAL_BASE_URL}/verify/twofa/secret`
export const EP_DISABLE_2FA_QRCODE = `${GLOBAL_BASE_URL}/disable/twofa`
export const EP_SAVE_USER_NAME = `${GLOBAL_BASE_URL}/add/username`
export const EP_REGISTER_STEP1 = `${GLOBAL_BASE_URL}/create/register/step/one`
export const EP_REGISTER_STEP2 = `${GLOBAL_BASE_URL}/create/register/step/two`
export const EP_REGISTER_STEP3 = `${GLOBAL_BASE_URL}/create/register/step/three`
export const EP_REGISTER_STEP4 = `${GLOBAL_BASE_URL}/create/register/step/four`
export const EP_REGISTER_STEP5 = `${GLOBAL_BASE_URL}/create/register/step/five`
export const EP_DELETE_DOC = `${GLOBAL_BASE_URL}/delete/user/docs`
export const EP_SKIP_DOCUMENT_UPLOAD = `${GLOBAL_BASE_URL}/skip/document/upload/step/five`
export const EP_GET_QUESTION_ANSWER = `${GLOBAL_BASE_URL}/get/question_answer`
// export const EP_GET_COUNTRIES_LIST = `${GLOBAL_BASE_URL}/get/country/list`
export const EP_GET_CITIES_LIST = `${GLOBAL_BASE_URL}/get/city/list`
export const EP_GET_SUM_SUB_RESPONSE = `${GLOBAL_BASE_URL}/get/user/ipv/details`
export const EP_RESOLVE_DOC_ISSUE = `${GLOBAL_BASE_URL}/resolve/user/ipv/details`
export const EP_LANGUAGE_API = `${GLOBAL_BASE_URL}/update/default/language`
export const EP_SAVE_FIREBASE_TOKEN = `${GLOBAL_BASE_URL}/save/firebase/token`
export const EP_GET_ACCOUNT_PRIORITY_LIST = `${GLOBAL_BASE_URL}/trading/account/priority`
export const EP_BANK_DEPOSIT_FOR_FEW_COUNTRIES = `${GLOBAL_BASE_URL}/get/gatech/url`
//===========================================plugin function api=========================================
export const EP_GET_WEBINAR_DATA = `${GLOBAL_BASE_URL}/get/webinar/blog`
export const EP_GET_POST_DATA = `${GLOBAL_BASE_URL}/get/posts`

export const EP_GET_AUTOCHARTIST_DATA = `${GLOBAL_BASE_URL}/get/autochartist/data`
export const EP_GET_AUTO_CHARTIST_WEB_URL = `${GLOBAL_BASE_URL}/get/autochartist/url`
export const EP_GET_SIGNAL_CENTRE_DATA = `${GLOBAL_BASE_URL}/get/signal-centre/data`
export const EP_GET_ECONOMIC_CALENDER_DATA = `${GLOBAL_BASE_URL}/get/calendar`
export const EP_GET_CALENDER_EVENT = "/get/ticker/history"
export const EP_GET_CURRENT_NEWS = `${GLOBAL_BASE_URL}/get/news`
export const EP_GET_CURRENT_NEWS_DETAIL = `${GLOBAL_BASE_URL}/fxstreet/post/details`
export const EP_GET_COUPON_LIST = `${GLOBAL_BASE_URL}/get/coupon/list`
export const EP_CHECK_COUPON_VALIDITY = `${GLOBAL_BASE_URL}/check/coupon/code`
export const EP_REQUEST_CALLBACK = `${GLOBAL_BASE_URL}/client/create/admin/task`
export const EP_UPDATE_LABEL = `${GLOBAL_BASE_URL}/update/account/label`


export const EP_COUPON_PLAN = `${GLOBAL_BASE_URL}/get/plan/coupons`
export const EP_GET_SUB_PLAN_LIST = `${GLOBAL_BASE_URL}/subscription/plan/list`
export const EP_GET_SUB_PLAN_DETAIL = `${GLOBAL_BASE_URL}/subscription/plan/detail`
export const EP_SUB_OPERATION_LINK = `${GLOBAL_BASE_URL}/subscription/operations/link`
export const EP_SUB_OPERATION = `${GLOBAL_BASE_URL}/subscription/operations`
export const EP_CREATE_SUB_TICKET = `${GLOBAL_BASE_URL}/create/subscription`
export const EP_UNSUB_SERVICE = `${GLOBAL_BASE_URL}/end/subscription`

export const EP_GET_VPS_LIST = `${GLOBAL_BASE_URL}/user/server/list`
export const EP_GET_VPS_DETAIL = `${GLOBAL_BASE_URL}/user/server/details`
export const EP_VPS_RESTART = `${GLOBAL_BASE_URL}/vps/server/on`
export const EP_VPS_REBOOT = `${GLOBAL_BASE_URL}/vps/server/reboot`
export const EP_VPS_SHUTDOWN = `${GLOBAL_BASE_URL}/vps/server/off`
export const EP_VPS_CHANGE_PASSWORD = `${GLOBAL_BASE_URL}/vps/server/reset/password`

export const EP_CREATE_ACCOUNT = `${GLOBAL_BASE_URL}/create/account`
export const EP_DEMO_DEPOSIT = `${GLOBAL_BASE_URL}/deposit/demo/account`

export const EP_TRADING_BONUS = `${GLOBAL_BASE_URL}/get/allowed/bonus`
export const EP_BONUS_REQUEST_OTP = `${GLOBAL_BASE_URL}/bonus/otp`
export const EP_BONUS_CHECK_OTP = `${GLOBAL_BASE_URL}/add/bonus`
export const EP_GET_BONUS_LIST = `${GLOBAL_BASE_URL}/get/bonus/list`
export const EP_REMOVE_BONUS = `${GLOBAL_BASE_URL}/remove/bonus`
export const EP_RESEND_EMAIL_LINK_BONUS = `${GLOBAL_BASE_URL}/account/operations/link`

export const EP_TICKET_REQUEST_OTP = `${GLOBAL_BASE_URL}/ticket/request/otp`
export const EP_TICKET_CHECK_OTP = `${GLOBAL_BASE_URL}/ticket/request`
export const EP_GET_TICKET_LIST = `${GLOBAL_BASE_URL}/list/ticket`
export const EP_GET_TICKET_DETAIL = `${GLOBAL_BASE_URL}/ticket/details`
export const EP_TICKET_REPLY = `${GLOBAL_BASE_URL}/create/ticket/thread`
export const EP_GET_TICKET_REASON_LIST = `${GLOBAL_BASE_URL}/get/ticket/reasons`
export const EP_TICKET_RESEND_OTP = `${GLOBAL_BASE_URL}/ticket/request/otp/resend`

export const EP_ACCOUNT_PASSWORD_REQUEST_OTP = `${GLOBAL_BASE_URL}/account/operations`
export const EP_ACCOUNT_PASSWORD_CHECK_OTP = `${GLOBAL_BASE_URL}/account/change/password`
export const EP_ACCOUNT_LEVERAGE_CHECK_OTP = `${GLOBAL_BASE_URL}/change/leverage`
export const EP_INTERNAL_TRANSFER_CHECK_OTP = `${GLOBAL_BASE_URL}/internal/transfer`
export const EP_RUN_ADD_LAST_SELECT_TRADING_ACCOUNT = `${GLOBAL_BASE_URL}/trading/account/history`
export const EP_RESEND_EMAIL_LINK_ACCOUNTS = `${GLOBAL_BASE_URL}/account/operations/link`
export const EP_AMOUNT_CONVERSION_API = `${GLOBAL_BASE_URL}/currency/converter`

export const EP_WITHDRAWAL_REQUEST_OTP = `${GLOBAL_BASE_URL}/withdrawal/request/otp`
export const EP_WITHDRAWAL_CHECK_OTP = `${GLOBAL_BASE_URL}/withdrawal/request`
export const EP_RESEND_EMAIL_LINK = `${GLOBAL_BASE_URL}/withdrawal/request/resend/otp`
export const EP_GET_WITHDRAWAL_METHODS = `${GLOBAL_BASE_URL}/get/withdrawal/methods`
export const EP_GET_WITHDRAWAL_DESC = `${GLOBAL_BASE_URL}/get/withdrawal/desc`

export const EP_GET_BITCOIN_DEPOSIT_ADDRESS = `${GLOBAL_BASE_URL}/get/deposit/address`
export const EP_GET_USER_CAN_DEPOSIT = `${GLOBAL_BASE_URL}/get/deposit/methods`
export const EP_TRANS_LIMIT_CURRENCY = `${GLOBAL_BASE_URL}/get/deposit/limit`
export const EP_GET_TRANSACTION_STATUS = `${GLOBAL_BASE_URL}/get/transaction/status`

export const EP_GET_TRADING_ACCOUNT_LIST = `${GLOBAL_BASE_URL}/get/trading/account/list`
export const EP_CREATE_API_SUBSCRIPTION = `${GLOBAL_BASE_URL}/create/api/subscription`
export const EP_GET_DEPOSIT_LIST = `${GLOBAL_BASE_URL}/get/deposit/list`
export const EP_CREDIT_CARD_DEPOSIT = `${GLOBAL_BASE_URL}/get/deposit/url`
export const EP_CRYPTO_DEPOSIT = `${GLOBAL_BASE_URL}/deposit/request/operations`


export const EP_GET_WITHDRAWAL_LIST = `${GLOBAL_BASE_URL}/get/withdrawal/list`
export const EP_GET_TRADING_ACCOUNT_DETAILS = `${GLOBAL_BASE_URL}/get/account/details`

export const EP_QUESTION_STEP3 = `${GLOBAL_BASE_URL}/create/register/step/three`
export const EP_RISKSCORE_UPDATE = `${GLOBAL_BASE_URL}/update/riskscore/agreed`

export const EP_GET_PROFIT_CHART_DATA = `${GLOBAL_BASE_URL}/get/profit/data`
export const EP_GET_BALANCE_DATA = `${GLOBAL_BASE_URL}/get/balance/data`
export const EP_GET_EQUITY_DATA = `${GLOBAL_BASE_URL}/get/equity/data`
export const EP_GET_TRADING_DATA = `${GLOBAL_BASE_URL}/get/trading/data`
export const EP_GET_IDENFY_TOKEN = `${GLOBAL_BASE_URL}/get/idenfy/token`

export const EP_ADD_BANK = `${GLOBAL_BASE_URL}/add/bank/account`
export const EP_GET_BANK_LIST = `${GLOBAL_BASE_URL}/get/bank/accounts`

export const EP_GET_USER_PROFILE = `${GLOBAL_BASE_URL}/get/user/profile`
export const EP_GET_USER_REGISTERATION_STEP_DATA = `${GLOBAL_BASE_URL}/get/registration/data`
export const EP_USER_NOTIFICATIONS = `${GLOBAL_BASE_URL}/common/get/notifications`

export const EP_RESET_PASSWORD_FROM_HASH = `${GLOBAL_BASE_URL}/change/via/forgot/password`

export const EP_CHANGE_PASSWORD = `${GLOBAL_BASE_URL}/change/password`
export const EP_REFER_Friend = `${GLOBAL_BASE_URL}/save/referall/email`
export const EP_REFER_LIST = `${GLOBAL_BASE_URL}/get/referall/List`
export const EP_REFER_DELETE = `${GLOBAL_BASE_URL}/delete/referall/List`
export const EP_GET_AUTOCHARTIST_URL = `${GLOBAL_BASE_URL}/get/autochartist/url1`
export const EP_CONVERT_WALLET_BALANCE = `${GLOBAL_BASE_URL}/api/convert/wallet/balance`
export const EP_GET_HOOPS_DATA = `${GLOBAL_BASE_URL}/get/hoops/data`

//wallet
export const EP_CREATE_WALLET_ACCOUNT = `${GLOBAL_BASE_URL}/create/wallet`
export const EP_GET_WALLET_DETAIL = `${GLOBAL_BASE_URL}/get/wallet/details`
export const EP_GET_WALLET_TRANSACTION = `${GLOBAL_BASE_URL}/get/wallet/transactions`
export const EP_GET_WALLET_BALANCE = `${GLOBAL_BASE_URL}/get/wallet/balance`



//loyality
export const EP_GET_LOYALITY_CARD_DETAIL = `${GLOBAL_BASE_URL}/loyalty/card/details`
export const EP_GET_LOYALITY_TXN_LIST = `${GLOBAL_BASE_URL}/loyalty/transaction/list`
export const EP_LOYALITY_REQUEST_OTP = `${GLOBAL_BASE_URL}/loyalty/points/transaction`
export const EP_LOYALITY_REDEEM_CHECK_OTP = `${GLOBAL_BASE_URL}/redeem/loyalty/points`
export const EP_LOYALITY_TRANSFER_CHECK_OTP = `${GLOBAL_BASE_URL}/transfer/loyalty/points`
export const EP_LOYALITY_EMAIL_VERIFY_LINK = `${GLOBAL_BASE_URL}/loyalty/points/transaction/link`

export const EP_GET_SUBSCRIPTION_DETAIL = `${GLOBAL_BASE_URL}/subscription/transaction/list`
export const EP_SUBSCRIPTION_REQUEST_OTP = `${GLOBAL_BASE_URL}/update/subscription/payment/transaction`
export const EP_SUBSCRIPTION_CHECK_OTP = `${GLOBAL_BASE_URL}/update/subscription/payment`
export const EP_SUBSCRIPTION_RESEND_EMAIL_LINK = `${GLOBAL_BASE_URL}/update/subscription/payment/transaction/link`


export const EP_UNSUBSCRIBE_REQUEST_OTP = `${GLOBAL_BASE_URL}/unsubscribe/transaction`
export const EP_UNSUBSCRIBE_CHECK_OTP = `${GLOBAL_BASE_URL}/unsubscribe/request`
export const EP_Unsubscribe_RESEND_EMAIL_LINK = `${GLOBAL_BASE_URL}/unsubscribe/transaction/link`

export const EP_LOYALITY_SUBSCRIP_VERIFY = `${GLOBAL_BASE_URL}/create/loyalty/vps/subscription`

export const EP_TUT_COURSE_CATEGORY = `${GLOBAL_BASE_URL}/get/tutorial/course_category`
export const EP_GET_VIDEO_TUTORIAL = `${GLOBAL_BASE_URL}/get/tutorial`

export const EP_GET_NOTIFICATION_LIST = `${GLOBAL_BASE_URL}/common/get/notification`
export const EP_READ_UNREAD_NOTIFICATION = `${GLOBAL_BASE_URL}/common/update/unread/notification/as/read`

export const EP_GET_PRAXIS_URL = `${GLOBAL_BASE_URL}/praxis/request`
export const EP_BITCOIN_CONVERSION = `${GLOBAL_BASE_URL}/get/currency/conversion`

export const EP_GET_CRYPTO_Methods = `${GLOBAL_BASE_URL}/get/crypto/method`
export const EP_ADD_CRYPTO_Methods = `${GLOBAL_BASE_URL}/add/crypto/method`

export const EP_DELETE_TRADING_ACCOUNT = `${GLOBAL_BASE_URL}/disable/account`

export const EP_GET_WITHDRAWAL_METHOD_LIST = `${GLOBAL_BASE_URL}/get/withdrawal/methods`
export const EP_GET_WITHDRAWAL_CRYPTO_METHOD_LIST = `${GLOBAL_BASE_URL}/add/crypto/method`
export const EP_GET_CONVERSION_TAG_COUNT = `${GLOBAL_BASE_URL}/get/count/deposit`
export const EP_UPDATE_CONVERSION_TAG_COUNT = `${GLOBAL_BASE_URL}/get/count/deposit`

//----------------------------Close withdrawal request Ticket-------------------------------------------
export const EP_CLOSE_SUPPORT_TICKET = `${GLOBAL_BASE_URL}/cancel/withdrawal/request`

//----------------------------Ticket transection detail-------------------------------------------
export const EP_TICKET_TRANS_DETAIL = `${GLOBAL_BASE_URL}/get/withdrawal/transactionDetail`

//----------------------------Add Payment method crypto-------------------------------------------
export const EP_ADD_PAYMENT_METHOD_CRYPTO = `${GLOBAL_BASE_URL}/get/withdrawal/transactionDetail`












