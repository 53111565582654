<template>
  <div>
    <p style="color: #fff; margin: 0">
      Please verify your account to create live account
    </p>
    <a style="color: rgb(119, 6, 6)" href="javascript:void(0);">
      Click to begin verification process
    </a>
  </div>
</template>
