import moment from "moment"
import staticVars from "./staticVars"
import { useMainStore } from '@/store'

export const convertStringToInteger = (val) => {
    return parseFloat(val)
}

export const convertToTwoDecimalDigits = (val, digits = 2) => {
    return parseFloat(parseFloat(val).toFixed(digits))
}

export const formatDate = (date, time) => {
    if(date != '' && date != null && date != '0000-00-00'){
        if (time) {
        return moment(date).format('Do MMM, YYYY hh:mm a')
        } else {
            return moment(date).format('Do MMM, YYYY')
        }
    }else{
        return 
    }
}

export const getTicketReason = (reasonID, titleID) => {
    const store = useMainStore()
    try {
        if (Object.keys(store.ticketReasons).length) {
            if (store.ticketReasons[reasonID] && store.ticketReasons[reasonID].categories && store.ticketReasons[reasonID].categories.length) {
                let reason = store.ticketReasons[reasonID].categories.find(i => i.id === titleID)
                if (reason) {
                    return { parent: store.ticketReasons[reasonID], child: reason }
                } else {
                    return { parent: store.ticketReasons[reasonID], child: { title: '' } }
                }
            } else {
                return { parent: { title: '' }, child: { title: '' } }
            }
        } else {
            return { parent: { title: '' }, child: { title: '' } }
        }
    } catch (e) {
        return { parent: { title: '' }, child: { title: '' } }
    }
}

export const priceWithCurrency = (account, value) => {
    if (account.currency && value) {
        value = parseFloat(value)
        if (value < 0) {
            return `- ${staticVars.currency[account.currency]}${Math.abs(value)}`
        } else {
            return `${staticVars.currency[account.currency]}${value}`
        }
    } else {
        return '$0'
    }
}