<style>
.loaderContent {
  background-color: #fff;
  padding: 5%;
  text-align: center;
}
.addressBox {
  margin-top: 20px;
  background-color: rgb(109 221 24);
  padding: 1%;
  text-align: center;
  width: 50%;
  margin: 0 auto;
}
</style>
<template>
  <div class="content-holder">
    <div class="form-holder acct-transfer">
      <ul id="fundsForm" class="align-items-start">
        <li>
          <label for="">Account Type</label>
          <div class="position-relative">
            <select
              class="drop-search d-block"
              name="account_type"
              v-model="account_type"
              @change="
                trading_acc = '';
                submitted = false;
              "
            >
              <option value="">Select type</option>
             <!--  <option value="0">Demo</option> -->
              <option value="1">Live</option>
            </select>
            <span
              class="text-danger"
              v-if="account_type == '0' && submitted && error.account"
              >{{ error.account }}</span
            >
          </div>
        </li>
        <!-- <li v-if="account_type != ''">
          <label for="">Trading Account</label>
          <div class="position-relative w-100">
            <AccountsDropdown />
            <span class="text-danger" v-if="submitted && error.account">{{
              error.account
            }}</span>
          </div>
        </li> -->
        <li class="half-width f-right">
          <label for="">Trading Account</label>
          <div class="position-relative">
            <select
              class="form-control d-block"
              name="trading_acc"
              v-model="trading_acc"
              @change="
                selectTradingAccount();
                submitted = false;
              "
            >
              <option value="" disabled>Select Trading Account</option>
              <!-- <option
                v-for="account of GetTradingAccount"
                :value="account.account_id"
                :key="account.account_id"
              >
                {{ account.account_id }} ({{
                  account.demo_live == "1" ? "Live" : "Demo"
                }})
              </option> -->
              <optgroup label="Live MT4">
                <option :value="list.account_id" v-for="list,index in MergeAcc('1','MT4')" :key ="index" >{{list.account_id}}</option>
              </optgroup>
              <optgroup label="Live MT5">
                <option :value="list.account_id" v-for="list,index in MergeAcc('1','MT5')" :key ="index" >{{list.account_id}}</option>
              </optgroup>
              <optgroup label="Live ZTP">
                <option :value="list.account_id" v-for="list,index in MergeAcc('1','ZTP')" :key ="index" >{{list.account_id}}</option>
              </optgroup>
            </select>
              
            <span class="text-danger" v-if="submitted && error.selectTrading">{{
              error.selectTrading
            }}</span>
          </div>
          <!--  <ErrorMessage name="trading_acc" /> -->
        </li>
        <li
          v-if="store.lastSelectedAccount.account_id && account_type == '0'"
          class="currency"
        >
          <label for="">Balance</label>
          <div class="position-relative w-100">
            <input
              type="text"
              name="amounts"
              class="d-block"
              placeholder="Enter deposit amount"
              v-model="store.tradingAccountDetail.margin_detail.balance"
              disabled
            />
            <!-- <div class="cur">USD</div> -->
            <span class="text-danger" v-if="submitted && error.amount">{{
              error.amount
            }}</span>
          </div>
          <a
            href="#"
            class="btn btn-blue popup-link-holder"
            @click="
              CheckFund();
              $parent.deposit_amount = '';
            "
            >FUND</a
          >
        </li>
        <li
          v-if="store.lastSelectedAccount.account_id && account_type == '1'"
          class="currency"
        >
          <label for="">Balance</label>
          <div class="position-relative w-100">
            <input
              :disabled="true"
              type="text"
              :value="store.tradingAccountDetail.margin_detail.balance"
              class="d-block"
            />
          </div>
        </li>
        <li
          v-if="store.lastSelectedAccount.account_id && account_type == '1'"
          class="currency"
        >
          <label for="">Amount</label>
          <div class="position-relative w-100">
            <input
              type="text"
              name="amount"
              placeholder="Enter deposit amount"
              v-model="amount"
              class="d-block"
            />
            <!-- <div class="cur">USD</div> -->
            <span class="text-danger" v-if="submitted && error.msg">{{
              error.msg
            }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="account_type == 1">
      <div v-if="account_type == 1" class="dep-nav">
        <ul>
          <li
            v-for="type of static_vars.depositMethods.keys"
            :key="type"
            :class="type == selectedMethod ? 'active' : ''"
          >
            <a @click="setDepositType(type)">{{ type }}</a>
          </li>
        </ul>
      </div>

      <div
        v-if="selectedMethod == 'All'"
        class="table-holder table-holder2 t-center"
      >
        <table v-for="type of updatedKeys" :key="type">
          <tr>
            <th>{{ type }}</th>
            <th>Region</th>
            <th>Currencies</th>
            <th>Processing Time</th>
            <th>Action</th>
          </tr>
          <tr v-for="method of allMethods(type)" :key="method.name">
            <td><img :src="`assets/images/${method.image}`" alt="" /></td>
            <td>{{ method.region }}</td>
            <td>{{ method.currencies }}</td>
            <td>{{ method.processingTime }}</td>
            <td>
              <button
                type="button"
                @click="startFunding(method.methodID, method.minimumDeposit, method.currencies, method.name)"
                class="btn btn-blue" v-if="method.name != 'Eurobank' && method.name != 'Dixipay'"
              >
                FUND
              </button>
              <button type="button" class="btn btn-blue" v-on:click="viewMore = method.name" v-else>View More</button>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="table-holder table-holder2 t-center">
        <table>
          <tr>
            <th>{{ selectedMethod }}</th>
            <th>Region</th>
            <th>Currencies</th>
            <th>Processing Time</th>
            <th>Action</th>
          </tr>
          <tr v-for="method of allMethods(selectedMethod)" :key="method.name">
            <td><img :src="`assets/images/${method.image}`" alt="" /></td>
            <td>{{ method.region }}</td>
            <td>{{ method.currencies }}</td>
            <td>{{ method.processingTime }}</td>
            <td>
              <button
                type="button"
                @click="startFunding(method.methodID, method.minimumDeposit, method.currencies, method.name)"
                class="btn btn-blue" v-if="method.name != 'Eurobank' && method.name != 'Dixipay'"
              >
                FUND
              </button>
              <button type="button" class="btn btn-blue" v-on:click="viewMore = method.name" v-else>View More</button>
            </td>
          </tr>
        </table>
      </div>
      <div class="table-holder table-holder2 t-center d-flex align-items-start" style="margin-top: 40px;" v-if="viewMore && (selectedMethod == 'Bank Wire' || selectedMethod == 'All')">  
        <table style="margin-right: 40px !important;" v-if="viewMore == 'Eurobank'">
          <tr>
            <th colspan="2">EFG USD</th>
          </tr>
          <tr>
            <td>Currency</td>
            <td>USD</td>
          </tr>
          <tr>
            <td>Swift Code</td>
            <td>ERBKGRAAXXX</td>
          </tr>
          <tr>
            <td>Beneficiary Name</td>
            <td>TRIPLE A EXPERTS</td>
          </tr>
          <tr>
            <td>IBAN</td>
            <td>GR9502600590000421200023114</td>
          </tr>
          <tr>
            <td>Bank Address</td>
            <td>Akti Kondili 26-28, 18545 Piraeus, GREECE</td>
          </tr>
          <tr>
            <td colspan="2"><span>*</span> EURO Bank accepts deposits only in Euro</td>
          </tr>
        </table>
        <table v-if="viewMore == 'Dixipay'">
          <tr>
            <th colspan="2">Dixipay</th>
          </tr>
          <tr>
            <td>Bank Name</td>
            <td>DIXIPAY LTD</td>
          </tr>
          <tr>
            <td>SWIFT Code</td>
            <td>DIXPGB21</td>
          </tr>
          <tr>
            <td>Beneficiary Name</td>
            <td>Alpha Global Limited</td>
          </tr>
          <tr>
            <td>IBAN</td>
            <td>GB78DIXP00997890200462</td>
          </tr>
          <tr>
            <td>Currency</td>
            <td>EUR</td>
          </tr>
          <tr>
            <td>City</td>
            <td>London</td>
          </tr>
          <tr>
            <td>Country</td>
            <td>United Kingdom</td>
          </tr>
          <tr>
            <td colspan="2"><span>*</span> Dixipay accepts deposits only in Euro</td>
          </tr>
        </table>
      </div>
      <SkrillForm
        :transaction_id="paymentID"
        :account_id="store.lastSelectedAccount.account_id"
        :currency_selected="store.lastSelectedAccount.currency"
        :amount="amount"
      />
      <div class="loaderOverlayFull" v-if="walletAddress">
        <div class="loaderContent">
          <div style="margin-bottom: 20px">
            <p>
              You can deposit bitcoins in the following address and equivalent
              amount in the selected account currency will be added after
              conversion
            </p>
            <div class="addressBox">
                <strong>{{ walletAddress }}</strong>
                <div class="position-absolute">
                    <button class="copy" type="button" @click="copy(walletAddress)">Copy!</button>
                    <p class="copytext" v-show="copyMsg != ''">{{ copyMsg }}</p>
                </div>
            </div>
          </div>
          <button
            type="button"
            @click="walletAddress = null; copyMsg = ''"
            class="btn btn-blue"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
//import AccountsDropdown from "@/components/shared/AccountsDropdown";
import SkrillForm from "@/components/shared/SkrillForm";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  components: {
    //AccountsDropdown,
    SkrillForm,
  },
  data() {
    return {
      name: "deposit",
      width: 0,
      walletAddress: null,
      selectedMethod: "All",
      amount: 0,
      minimumAmount: 10,
      error: {
        account: null,
        amount: null,
        msg: null,
        selectTrading: null,
      },
      paymentID: null,
      submitted: false,
      account_type: "1",
      trading_acc: "",
      copyMsg: "",
      viewMore : ""
    };
  },
  watch: {
    amount() {
      if (this.minimumAmount && this.store.lastSelectedAccount.currency) {
        if (parseFloat(this.amount) < this.minimumAmount) {
          this.error.amount = `Minimum deposit amount is ${this.COMMON.priceWithCurrency(
            this.store.lastSelectedAccount,
            this.minimumAmount
          )}`;
        } else {
          this.submitted = false;
          this.error.amount = null;
        }
      }
    },
  },
  computed: {
    updatedKeys() {
      return this.static_vars.depositMethods.keys.filter(
        (i) => i != "All" && i != "AAAFx Wallet"
      );
    },
    GetTradingAccount() {
      return this.store.tradingAccounts.records
        .filter((val) => val.demo_live == this.account_type)
        .map((i) => i);
    },
  },
  mounted() {
    this.width = window.innerWidth;
    this.trading_acc =
      this.store.lastSelectedAccount.demo_live == "1"
        ? this.store.lastSelectedAccount.account_id
        : "";
  },
  methods: {
    MergeAcc(acc_type,platform){
        let demoMT4 = this.store.tradingAccounts.records.filter(i => i.demo_live == acc_type && i.platform == platform).map(val => val)
        return demoMT4 || []
    },
    copy(data){
      try{
        navigator.clipboard.writeText(data);
        this.copyMsg = 'Copied'
        setTimeout(() => {
            this.copyMsg = ''
        }, 3000);
      }catch(e){
        this.copyMsg = 'Unable to copy'
        setTimeout(() => {
            this.copyMsg = ''
        }, 3000);
      }
    },
    CheckFund() {
      this.submitted = true;
      // if (
      //   (Object.keys(this.store.tradingAccountDetail.margin_detail).length == 0) ||  (parseInt(this.store.tradingAccountDetail.margin_detail.balance) < 0 ||
      //   parseInt(this.store.tradingAccountDetail.margin_detail.balance) <
      //     this.minimumAmount)
      // ) {
      //   this.submitted = true;
      //   this.$parent.AddepsoitFund = false;
      //   this.error.account = null;
      //   if (this.trading_acc != "") {
      //     this.error.amount = `Minimum deposit amount is ${
      //       this.static_vars.currency[this.store.lastSelectedAccount.currency]
      //     }${Math.abs(this.minimumAmount)}`;
      //   } else {
      //     this.error.selectTrading = "Please select trading account";
      //   }
      // } 
      if(this.trading_acc == ""){
        this.error.selectTrading = "Please select trading account";
      }
      else {
        this.error.amount = null;
        this.submitted = false;
        this.$parent.AddepsoitFund = true;
      }
    },
    selectTradingAccount() {
      let acct = this.store.tradingAccounts.records.find(
        (i) => i.account_id == this.trading_acc
      );
      let keys = {
        dataRequired: {
          margin_detail: true,
          open_trades: false,
          history: false,
          account_detail: false,
        },
      };
      this.store.setLastSelectedAccount(acct, keys, false, "");
    },
    setDepositType(e) {
      this.selectedMethod = e;
      this.viewMore = ""
    },
    allMethods(type) {
      // if(this.store.user.profile.user_id == 20079 || this.store.user.profile.user_id == 18152){
      //   return this.static_vars.depositMethods.testmethods.filter(
      //     (i) => i.type === type
      //   );
      // }else{
        return this.static_vars.depositMethods.methods.filter(
          (i) => i.type === type
        );
      //}
    },
    startFunding(id, min, currency, method_name) {
      this.viewMore = ""
      if (this.account_type == "1") {
        if (this.trading_acc != "") {
          this.error.selectTrading = null;
          this.error.account = "";
          this.submitted = true;
          this.minimumAmount = min;
          if (!this.store.lastSelectedAccount.account_id) {
            this.error.account = "Please select an account first";
            document
              .getElementById("fundsForm")
              .scrollIntoView({ behavior: "smooth" });
            return;
          } else {
            this.error.account = null;
          }
          if (this.amount < this.minimumAmount) {
            this.error.msg = `Minimum deposit amount is ${this.COMMON.priceWithCurrency(
              this.store.lastSelectedAccount,
              this.minimumAmount
            )}`;
            document
              .getElementById("fundsForm")
              .scrollIntoView({ behavior: "smooth" });
            return;
          } else {
            this.error.msg = null;
            this.submitted = false;
          }
          let data = {
            account_id: this.store.lastSelectedAccount.account_id,
            amount: parseFloat(this.amount),
            currency: this.store.lastSelectedAccount.currency,
          };

          if (id == 2) {
            data["method_id"] = "2";
            data["payment_method_email"] = this.store.user.email;
          }
          if (id == 3) {
            data["method_id"] = "3";
            data["payment_method_email"] = this.store.user.email;
          }
          if (id == 17) {
            data["method_id"] = "17";
          }
          if (id == 18) {
            data["currency"] = currency
            data["method_id"] = "18";
          }

          window.dataLayer = window.dataLayer || [];
           window.dataLayer.push({
            'event' : 'addfund',
            'fundMethod' : method_name
           });


          this.store
            .generateDepositURL(data, true)
            .then((response) => {
              if (id == 2) {
                this.paymentID = response.trans_id;
                setTimeout(() =>{
                document.getElementById("skrillForm").submit();
                },1000)
              }
              if (id == 18) {
                this.walletAddress = response.address;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.submitted = true;
          this.error.selectTrading = "Please select trading account";
        }
      } else {
        this.submitted = true;
        this.error.selectTrading = null;
        this.error.account = "Please Select Live account";
      }
    },
  },
};
</script>
