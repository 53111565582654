<template>
  <div class="headerSection clearfix">
    <div class="left-nav f-left">
      <router-link to="/dashboard" class="logo-holder f-left">
        <img width="161" src="assets/images/logo.svg" alt="" />
      </router-link>

      <div v-if="$route.name != 'Web Trader'" class="menu-nav-link hide f-left">
        <a
          @click="$parent.toggleSidebar"
          id="toggle-link-bar"
          class="toggle-link-bar"
          ><i class="fas fa-bars"></i
        ></a>
      </div>
    </div>
    <div class="account-holder f-right t-right">
      <div class="d-flex align-items-center justify-content-end mr-10">
        <!-- <router-link
          v-if="!store.user.verified_status"
          class="routerLink"
          to="/verify"
        >
          <p class="mb-0">Complete Registration</p>
          v-if="parseInt(store.user.verified_status) == 1 && parseInt(store.user.pro_user) == 0"
        </router-link> -->
        <router-link
         
          class="btn btn-yellow popup-link-holder"
          to="/pro"
        >
          <p class="mb-0">Become a Pro</p>
        </router-link>
        <div class="name-holder">
          <!-- <router-link to="loyalty-program.html" class="rewardpoints-holder col">
          <h5><span>350</span> Reward Points</h5>
          <div class="level-holder f-right">
            <div class="percentage" style="width: 40%"></div>
            <span class="level1"
              ><hr />
              A</span
            >
            <span class="level2">
              <hr />
              AA</span
            >
            <span class="level3"
              ><hr />
              AAA</span
            >
            <span class="level4"
              ><hr />
              AAA+</span
            >
          </div>
        </router-link> -->
          <div class="profile-sub col">
            <div class="points-holder link">
              <div class="col name">
                <p>Welcome</p>
                <h5
                  :title="`${store.user.profile.firstname} ${store.user.profile.lastname}`"
                  class="elips"
                >
                  {{ store.user.profile ? store.user.profile.firstname : "" }}
                  <!--  {{ store.user.profile ? store.user.profile.lastname : "" }} -->
                </h5>
              </div>

              <div class="col arrow">
                <i class="fas fa-angle-down"></i>
              </div>
            </div>

            <ul class="profile-menu">
              <li>
                <router-link to="/profile">
                  <div class="icon-holder">
                    <img src="assets/images/prof1.png" alt="" />
                    <img src="assets/images/prof1a.png" alt="" class="hover" />
                  </div>
                  Profile</router-link
                >
              </li>
              <li>
                <router-link to="/security">
                  <div class="icon-holder">
                    <img src="assets/images/prof2.png" alt="" />
                    <img src="assets/images/prof2a.png" alt="" class="hover" />
                  </div>
                  {{
                    store.user.profile &&
                    store.user.profile.gAuthenticatorEnabled == 0
                      ? "Enable 2FA"
                      : "Disable 2FA"
                  }}</router-link
                >
              </li>
              <!-- <li>
              <router-link to="loyalty-program">
                <div class="icon-holder">
                  <img src="assets/images/prof3.png" alt="" />
                  <img src="assets/images/prof3a.png" alt="" class="hover" />
                </div>
                Loyal Points Program</router-link
              >
            </li> -->
              <li>
                <router-link to="/request-call">
                  <div class="icon-holder">
                    <img src="assets/images/prof4.png" alt="" />
                    <img src="assets/images/prof4a.png" alt="" class="hover" />
                  </div>
                  Request Callback</router-link
                >
              </li>
              <li>
                <router-link to="/support">
                  <div class="icon-holder">
                    <img src="assets/images/prof5.png" alt="" />
                    <img src="assets/images/prof5a.png" alt="" class="hover" />
                  </div>
                  Support Ticket</router-link
                >
              </li>
              <li>
                <router-link to="/change-pass">
                  <div class="icon-holder">
                    <img src="assets/images/prof6.png" alt="" />
                    <img src="assets/images/prof6a.png" alt="" class="hover" />
                  </div>
                  Change Password</router-link
                >
              </li>
              <li>
                <a @click.prevent="store.logout()">
                  <div class="icon-holder">
                    <img src="assets/images/prof7.png" alt="" />
                    <img src="assets/images/prof7a.png" alt="" class="hover" />
                  </div>
                  Logout</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  watch: {
    "store.user"(val) {
      if ("deposit_count_flag" in val) {
        if (val.deposit_count_flag === 0) {
          this.store.getConversionTagCount();
        }
      }
    },
  },
  mounted() {},
};
</script>
