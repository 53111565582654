import { createRouter, createWebHistory } from 'vue-router'
import { useMainStore } from '@/store'

//Auth
import Login from '@/views/auth/login.vue'
import ForgorPassword from '@/views/auth/forgot-password.vue'
import ResetPassword from '@/views/auth/reset-password.vue'
import Register from '@/views/auth/register.vue'

//Entry point
import Index from '@/views/index.vue'

//User
import Dashboard from '@/views/user/dashboard.vue'
// const Verify = () => import('@/views/user/verify.vue')
const ChangePass = () => import('@/views/user/change-pass.vue')
const Profile = () => import('@/views/user/profile.vue')
const Security = () => import('@/views/user/account-security.vue')
const pro = () => import('@/views/user/become-pro.vue')


//Account
const ManageAccount = () => import('@/views/account/manage-account.vue')
const CreateAccount = () => import('@/views/account/create-account.vue')
const AddLabel = () => import('@/views/account/add-label.vue')
const ChangeAcctLeverage = () => import('@/views/account/change-acct-leverage.vue')
const InternalTransfer = () => import('@/views/account/internal-transfer.vue')
const ResetInvestorPass = () => import('@/views/account/reset-investor-pass.vue')
const ResetMasterPass = () => import('@/views/account/reset-master-pass.vue')
const OperationsVerify = () => import('@/views/account/operations-verify.vue')
const ResetTradingPass = () => import('@/views/account/reset-trading-pass.vue')


//Support
const Support = () => import('@/views/support/support.vue')
const TicketDetails = () => import('@/views/support/ticket-details.vue')
const OpenTickets = () => import('@/views/support/open-tickets.vue')
const ClosedTickets = () => import('@/views/support/closed-tickets.vue')
const RequestCall = () => import('@/views/support/request-call.vue')

//Tools
const Tools = () => import('@/views/tools/index.vue')

//Bonus
const Bonus = () => import('@/views/bonus/bonus.vue')
const ReferEarn = () => import('@/views/bonus/refer-earn.vue')
const referralList = () => import('@/views/bonus/referral-list.vue')

//Partner
const BecomeIb = () => import('@/views/partner/become-ib.vue')
const PammAcct = () => import('@/views/partner/pamm-acct.vue')
const Partner = () => import('@/views/partner/index.vue')

//Funds
const Funds = () => import('@/views/funds/index.vue')
const RequestVerify = () => import('@/views/funds/request_verify.vue')
const AddPaymentMethod = () => import('@/views/funds/add_payment_method.vue')

//policy
const Policy = () => import('@/views/policy/privacy.vue')

//Others
const WebTrader = () => import('@/views/others/web-trader.vue')

const routes = [
  { path: '/privacy', name: 'Privacy', component: Policy},
  { path: '/:pathMatch(.*)*', component: Dashboard },
  { path: '/login', name: 'Login', component: Login, meta: { requiresAuth: false } },
  { path: '/forgot-password', name: 'Forgot Password', component: ForgorPassword, meta: { requiresAuth: false } },
  { path: '/reset-password/:uid', name: 'Reset Password', component: ResetPassword, meta: { requiresAuth: false } },
  { path: '/register', name: 'Register', component: Register, meta: { requiresAuth: false } },
  { path: '/web-trader', name: 'Web Trader', component: WebTrader, meta: { requiresAuth: true } },

  { path: '/', component: Index, meta: { requiresAuth: true },
    children: [
      // { path: '/verify', component: Verify, name: 'Verify', meta: { requiresAuth: true } },
      { path: '/account-verify', component: OperationsVerify, name: 'Operations Verify', meta: { requiresAuth: true } },
      { path: '/pro', component: pro, name: 'pro', meta: { requiresAuth: true } },
      { path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { requiresAuth: true } },
      { path: '/bonus', name: 'Bonus', component: Bonus, meta: { requiresAuth: true } },
      { path: '/add-label', name: 'Add Label', component: AddLabel, meta: { requiresAuth: true } },
      { path: '/become-ib', name: 'BecomeIb', component: BecomeIb, meta: { requiresAuth: true } },
      { path: '/change-acct-leverage', name: 'Change Acct Leverage', component: ChangeAcctLeverage, meta: { requiresAuth: true } },
      { path: '/change-pass', name: 'Change Pass', component: ChangePass, meta: { requiresAuth: true } },
      { path: '/closed-tickets', name: 'Closed Tickets', component: ClosedTickets, meta: { requiresAuth: true } },
      { path: '/create-account', name: 'Create Account', component: CreateAccount, props: { default: true }, meta: { requiresAuth: true } },
      { path: '/manage-funds', name: 'Manage Funds', component: Funds, props: { default: true }, meta: { requiresAuth: true } },
      { path: '/security', name: 'Security', component: Security, meta: { requiresAuth: true } },
      { path: '/internal-transfer', name: 'Internal Transfer', component: InternalTransfer, meta: { requiresAuth: true } },
      { path: '/profile', name: 'Profile', component: Profile, meta: { requiresAuth: true } },
      { path: '/manage-account', name: 'Manage Account', component: ManageAccount, meta: { requiresAuth: true } },
      { path: '/open-tickets', name: 'Open Tickets', component: OpenTickets, meta: { requiresAuth: true } },
      { path: '/partner', name: 'Partner', component: Partner, meta: { requiresAuth: true } },
      { path: '/pamm-acct', name: 'Pamm Acct', component: PammAcct, meta: { requiresAuth: true } },
      { path: '/refer-earn', name: 'Refer Earn', component: ReferEarn, meta: { requiresAuth: true } },
      { path: '/referral-list', name: 'Referral List', component: referralList, meta: { requiresAuth: true } },
      { path: '/request-call', name: 'Request Call', component: RequestCall, meta: { requiresAuth: true } },
      { path: '/reset-investor-pass', name: 'Reset Investor Pass', component: ResetInvestorPass, meta: { requiresAuth: true } },
      { path: '/reset-master-pass', name: 'Reset Master Pass', component: ResetMasterPass, meta: { requiresAuth: true } },
      { path: '/reset-trading-pass', name: 'Reset Trading Pass', component: ResetTradingPass, meta: { requiresAuth: true } },
      { path: '/support', name: 'Support', component: Support, meta: { requiresAuth: true } },
      { path: '/ticket-details', name: 'Ticket Details', component: TicketDetails, meta: { requiresAuth: true } },
      { path: '/tools', name: 'Tools', component: Tools, meta: { requiresAuth: true } },
      { path: '/withdrawal-verify', name: 'RequestVerify', component: RequestVerify, meta: { requiresAuth: true } },
      { path: '/add-payment-method', name: 'Payment Methods', component: AddPaymentMethod, meta: { requiresAuth: true } },

    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

router.beforeEach((to) => {
  if (to.path == '/') return { name: 'Dashboard' }
  const store = useMainStore()
  if (to.meta.requiresAuth && !store.user.token) return { name: 'Login' }
  if (!to.meta.requiresAuth && store.user.token) return { name: 'Dashboard' }
})
export default router
