<template>
  <div class="table-responsive">
    <table
      class="table customize opencloseTrade-table"
      v-if="
        store.lastSelectedAccount.platform &&
        (store.lastSelectedAccount.platform == 'MT4' ||
          store.lastSelectedAccount.platform == 'ZULU')
      "
    >
      <thead>
        <tr class="position-relative">
          <th>Login</th>
          <th>Order</th>
          <th>Symbol</th>
          <th>Volume</th>
          <th>Open Price</th>
          <th>Open Time</th>
          <th>Commission</th>
          <th>Type</th>
          <th>SL</th>
          <th>TP</th>
          <th>Close Price</th>
          <th>Close Time</th>
          <th>Swap</th>
          <th>PNL</th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody v-if="store.closedTrades && store.closedTrades.length">
        <tr v-for="list in store.closedTrades" :key="list[0]">
          <td>{{ list[0] }}</td>
          <td>{{ list[1] }}</td>
          <td>{{ list[2] }}</td>
          <td>{{ list[6] / 100 }}</td>
          <td>{{ list[3] }}</td>
          <td>{{ timeFunc(list[7]) }}</td>
          <td>{{ list[10] }}</td>
          <td>
            {{ list[12] == 0 ? "Buy" : list[12] == 1 ? "Sell" : "Deposit" }}
          </td>
          <td>{{ list[13] }}</td>
          <td>{{ list[14] }}</td>
          <td>{{ list[4] }}</td>
          <td>{{ list[12] !== 6 ? timeFunc(list[8]) : "-" }}</td>
          <td>{{ list[15] }}</td>
          <td>{{ list[5] }}</td>
          <td>{{ list[9] }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="selectHere">
          <td class="noRecordFound t-center" colspan="15">
            <p>No record found</p>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      class="table customize opencloseTrade-table"
      v-if="
        store.lastSelectedAccount.platform &&
        store.lastSelectedAccount.platform == 'MT5'
      "
    >
      <thead>
        <tr class="position-relative">
          <th>Login</th>
          <th>Order</th>
          <th>Symbol</th>
          <th>Type</th>
          <th>Volume</th>
          <th>Open Time</th>
          <th>Open Price</th>
          <th>Commission</th>
          <th>Swap</th>
          <th>PNL</th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody v-if="store.closedTrades && store.closedTrades.length">
        <tr v-for="list in store.closedTrades" :key="list[0]">
          <td>{{ list[0] }}</td>
          <td>{{ list[1] }}</td>
          <td>{{ list[3] }}</td>
          <td>
            {{ list[13] == 0 ? "Buy" : list[13] == 1 ? "Sell" : "Deposit" }}
          </td>
          <td>{{ list[9] }}</td>
          <td>{{ list[13] !== 2 ? timeFunc(list[12]) : "-" }}</td>
          <td>{{ list[4] }}</td>
          <td>{{ list[6] }}</td>
          <td>{{ list[24] }}</td>
          <td>
            {{
              COMMON.priceWithCurrency(
                store.lastSelectedAccount,
                COMMON.convertToTwoDecimalDigits(list[5])
              )
            }}
          </td>
          <td>
            {{ list[25] }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="selectHere">
          <td class="noRecordFound t-center" colspan="11">
            <p>No record found</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import moment from "moment";

export default {
  name: "Home",
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {};
  },
  methods: {
    selectTradingAccount(e) {
      this.store.setLastSelectedAccount(e);
    },
    timeFunc(getData) {
      return moment.unix(getData).format("Do MMM, YYYY HH:mm:ss");
    },
  },
};
</script>
