<template>
  <div class="login-page register-page reset-pass">
    <div id="main-wrapper" class="w-100">
        <div v-if="success">
          <div class="mfp-bg animated zoomIn mfp-ready"></div>
          <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor animated zoomIn mfp-ready" tabindex="-1" style="overflow: hidden auto;">
            <div class="mfp-container mfp-s-ready mfp-inline-holder">
              <div class="mfp-content">
                <div id="upload-confirm" class="white-popup-block popup-content popup-small">
                  <div class="content t-white t-center">
                     <p>Your login password has been successfully changed.</p>
                  </div>
                  <div class="btn-holder t-center">
                    <a href="#" class="btn btn-lblue close-popup w-auto" @click.prevent="$router.push('/login')">OK </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="login-page">
        <div class="register-holder center-div wow fadeIn">
          <img width="145" src="/assets/images/logo.svg" alt="" />
          <br />
            <br />
          <div class="form-holder">
          <h3 class="t-center">Reset Password</h3>
            <Form @submit="submit">
              <ul>
                <li class="full-width f-left">
                  <label for="">Password</label>
                  <div style="position: relative">
                    <Field
                      :type="inputtype"
                      class="form-control"
                      name="password"
                      v-model="form.password"
                      rules="required|min:8"
                      @focusin="showPassDiv = true"
                      @focusout="showPassDiv = false"
                    />
                    <vue-feather :type="iconType" class="toggle-password" @click.prevent="switchVisibility"></vue-feather>
                  </div>
                  <ErrorMessage name="password" />
                  <div id="pr-box" class="light" v-show="showPassDiv">
                    <i></i>
                    <div id="pr-box-inner">
                        <p>The minimum password length is 8 characters and must contain at least 1 lowercase letter, 1 capital letter 1 number and 1 special character.</p>
                        <ul>
                            <li class="pr-numcharacters"><span :class="(form.password.length >= 8)?'pr-ok':''"></span># of characters</li>
                            <li class="pr-useLowercase"><span :class="(lowercaseOK)?'pr-ok':''"></span>Lowercase letter</li>
                            <li class="pr-useUppercase"><span :class="(uppercaseOk)?'pr-ok':''"></span>Capital letter</li>
                            <li class="pr-useNumbers"><span :class="(numberOk)?'pr-ok':''"></span>Number</li>
                            <li class="pr-useSpecial"><span :class="(SpecialCharOk)?'pr-ok':''"></span>Special character</li>
                        </ul>
                    </div>
                  </div>
                </li>
                <li class="full-width f-left">
                  <label for="">Confirm Password</label>
                  <div style="position: relative">
                    <Field
                      :type="inputtype1"
                      class="form-control"
                      name="cpassword"
                      v-model="form.cpassword"
                      rules="required|confirmed:@password"
                    />
                    <vue-feather :type="iconType1" class="toggle-password" @click.prevent="switchVisibility1"></vue-feather>
                  </div>
                  <ErrorMessage name="cpassword" />
                </li>
              </ul>
              <div class="btn-holder">
                <button class="btn btn-yellow">
                  <span>Reset</span>
                </button>
              </div>
            </Form>
          </div>
          <div class="links">
            <p>Don’t have an account?</p>
            <router-link to="/register">Create an account Now!</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  mounted() {
    if (this.$route.params.uid) {
      this.form.hash = this.$route.params.uid;
    }
  },
  data() {
    return {
      form: {
        hash: "",
        password: "",
        cpassword: "",
      },
      showpassword: false,
      iconType : 'eye-off',
      inputtype : 'password',
      showpassword1 : false,
      iconType1 : 'eye-off',
      inputtype1 : 'password',
      showPassDiv : false,
      lowercaseOK : false,
      uppercaseOk : false,
      numberOk : false,
      SpecialCharOk : false,
      success : false
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  watch:{
    
    "form.password" : function(val){
        let lowerCase = new RegExp("[a-z]"),
            upperCase = new RegExp("[A-Z]"),
            numbers = new RegExp("[0-9]"),
            specialcharacter = new RegExp("[!,%,&,@,#,$,^,*,?,_,~]");
        if(val.match(lowerCase)) {
            this.lowercaseOK = true
        } else {
            this.lowercaseOK = false
        }
        if(val.match(upperCase)){
            this.uppercaseOk = true
        }else{
            this.uppercaseOk = false
        }
        if(val.match(numbers)){
            this.numberOk = true
        }else{
            this.numberOk = false
        }
        if(val.match(specialcharacter)){
            this.SpecialCharOk = true
        }else{
            this.SpecialCharOk = false
        }
        if(this.lowercaseOK && this.uppercaseOk && this.numberOk && this.SpecialCharOk && val.length >= 8){
            this.showPassDiv = false
        }
    }
  },
  methods: {
    submit() {
      this.store.resetPassword(this.form, true,this);
    },
    switchVisibility() {
        this.showpassword = !this.showpassword
        if(this.showpassword){
            this.inputtype = 'text'
            this.iconType = 'eye'
        }else{
            this.inputtype = 'password'
            this.iconType = 'eye-off'
        } 
    },
    switchVisibility1() {
        this.showpassword1 = !this.showpassword1
        if(this.showpassword1){
            this.inputtype1 = 'text'
            this.iconType1 = 'eye'
        }else{
            this.inputtype1 = 'password'
            this.iconType1 = 'eye-off'
        } 
    },
  },
};
</script>
