import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Toast from "vue-toastification";
import staticVars from './staticVars'
import * as COMMON_METHODS from './commonFunctions'
import vSelect from "vue-select";
import { defineRule } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import VPagination from "@hennge/vue3-pagination";
import Datepicker from 'vue3-date-time-picker';
import VueFeather from 'vue-feather';
import VueTelInput from 'vue3-tel-input'

import VueCookies from 'vue3-cookies'

//Css for npm packages
import "vue-select/dist/vue-select.css";
import "vue-toastification/dist/index.css";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import 'vue3-date-time-picker/dist/main.css';
import 'vue3-tel-input/dist/vue3-tel-input.css'

const app = createApp(App)

Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
});

//Create global variables
app.config.globalProperties.static_vars = staticVars
app.config.globalProperties.COMMON = COMMON_METHODS

//Pinia store with persisted state
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

//Components for global use
app.use(VueCookies)
app.use(pinia)
app.use(router)
app.use(Toast, {
    position: 'top-right',
    timeout: 3000,
    hideProgressBar: true,
    type: 'success'
});
app.use(VueTelInput);

app.component('v-select', vSelect)
app.component('v-paginate', VPagination)
app.component('date-picker', Datepicker);
app.component(VueFeather.name, VueFeather)

//App mounted
app.mount('#app')
