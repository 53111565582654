<template>
  <Header />
  <div
    id="main-container"
    class="d-flex align-items-top"
    :class="
      $route.name == 'Web Trader' || width > 1345
        ? 'toggle-sidebar'
        : sidebarVisible
        ? 'toggle-sidebar'
        : ''
    "
  >
    <Sidebar
      v-if="$route.name != 'Web Trader'"
      :width="width"
      :sidebarVisible="sidebarVisible"
    />
    <AppLoader v-if="store.loading" />
    <router-view />
  </div>
  <Footer v-if="$route.name != 'Web Trader'" />
</template>

<script>
import Header from "@/components/shared/Header";
import Footer from "@/components/shared/Footer";
import Sidebar from "@/components/shared/Sidebar";
import AppLoader from "@/components/shared/AppLoader.vue";
import { useMainStore } from "@/store";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      width: 0,
      sidebarVisible: false,
    };
  },
  components: { Header, Footer, Sidebar, AppLoader },
  mounted() {
    this.width = window.innerWidth;
    this.store.refreshUserData();
    this.store.getTicketReasons();
  },
  methods: {
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
  },
};
</script>

<style scoped>
/* :deep() .vs__dropdown-toggle {
  background-color: #fff;
} */
</style>
