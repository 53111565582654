export default {
    limitPerPage: 10,
    webTraderURL: 'https://trade.mql5.com/trade',
    skrill: {
        depositLink: 'deposit',
        pay_to_email: 'backoffice@fxview.com',
        status_url: process.env.VUE_APP_SKRILL_URL,
        merchant_id: '20619822',
        merchant_fields: 'tradingAccount,email,method_id',
        return_url_target: '3',
        cancel_url_target: '3',
    },
    withdrawalStatus: { '0': "Pending", '1': "Processed", '2': "Rejected", '3': "Canceled by User" },
    depositStatus: { '-1': "Transaction Initiated", '0': "Pending", '1': "Success", '2': "Declined", '3': "Declined", '4': "Unverified Account" },
    bonusType: { '1': "Add Bonus", '2': "Referral Bonus", '3': "Remove Bonus" },
    depositType: { '1': "Deposit", '2': "Internal Transfer", '3': "Loyalty Points" },
    withdrawalType: { '1': "Withdrawal", '2': "Internal Transfer", '3': "Subscription" },
    registrationType: [
        { label: "Individual", value: "0" },
        { label: "Corporate", value: "1" }
    ],
    leverages: [
        { label: "100X", value: "100" },
        { label: "200X", value: "200" },
        { label: "300X", value: "300" },
        { label: "400X", value: "400" },
        { label: "500X", value: "500" }
    ],
    accountTypes: [
        { label: 'ECN', value: 'ECN' },
        { label: 'ECN Plus', value: 'ECNP' },
        { label: 'ISLAMIC', value: 'ISL' },
        { label: 'ECN Zulu', value: 'ZULU' }
        /*{ label: 'Classic', value: 'CLASSIC' },
        { label: 'Profit Sharing', value: 'PROFIT_SHARING' }*/
    ],
    platforms: [
        { label: 'MetaTrader 4', value: 'MT4' },
        { label: 'MetaTrader 5', value: 'MT5' },
        // { label: 'Zulu Trading Platform', value: 'ZTP' }
    ],
    currency: {
        'USD': '$',
        'EUR': '€',
        // 'CAD': '$',
        'GBP': '£',
        'AUD': 'AU$',
    },
    currency_mt4: {
        'USD': '$',
        'EUR': '€',
        // 'AUD': 'AU$',
        /*'GBP': '£',
        'JPY': '¥',
        'CAD': '$',
        'CHF': 'CHf'*/
    },
    transactionFilter : {
        deposit : {
            1 : 'Deposits',
            2 : 'Internal Transfer'
        },
        withdrawal : {
            1 : 'Withdrawals',
            2 : 'Internal Transfer'
        }
    },
    trade_type : {
        1 :'Buy',
        2 : 'Sell'
    },
    // visibleMethods: { '1': "Bank", '2': "Skrill", '3': "Neteller", '4': "Trading Account", '5': "Debit/Credit card", '6': "Giropay", '7': "Przelewy", '8': "Rapid Transfer", '9': "Nordea", '10': "Klarna", '11': "NBP", '12': "TR", '13': "CR", '15': "Loyalty Transfer", '18': "Bitcoin", '17': "Praxis", '19': "LBT-Gatech", '20': "LBT-Payguru", '21': "LBT-PayRetailer", '22': "LBT-Rapid OZOW" },
     visibleMethods : {'1' : 'Bank' ,'2' :'Instant Deposit - Skrill', '3' : 'Neteller','4' : 'Trading Account', '5': 'Credit Card', '6': 'Giropay', '7':'Przelewy24', '8':'Rapid Transfer', '9':'Nordea', '10':'Klarna', '11': 'NBP', '12':'TR', '13':'CR', '15' : 'Loyalty Transfer', '18': 'Capital Wallet', '17': 'Praxis', '19' : 'LBT-Gatech', '20' : 'LBT-Payguru', '21': 'LBT-PayRetailer', '22': 'LBT-Rapid OZOW' , '26' : 'EuroBank', '27' : 'Performance Fee Subscription', '28' : 'InactivityFee', '29' : 'Paytoday', '30' : 'Reward', '31' : 'Alopay', '32' : 'Credit Transaction', '33' : 'PerformanceFee', '34' : 'Paymentasia', '36' : 'Refund',  '38' : 'PayPal', '39' : 'Qiwi', '40' : 'Credit Card - Wirecard', '41' : 'MoneyBookers', '42' : 'Teleingreso', '43' : 'AAAFX Promo Card',  '45' : 'AutoPay', '46' : 'Instant Deposit - Wirecard', '47' : 'EPS', '48' : 'DirectPay24',  '50' : 'iDeal','51' : 'Bitstamp',},
    withdrawalMethod : {'1' : 'Bank' ,'2' :'Instant Deposit - Skrill', '3' : 'Neteller','4' : 'Trading Account', '5': 'Credit Card', '6': 'Giropay', '7':'Przelewy24', '8':'Rapid Transfer', '9':'Nordea', '10':'Klarna', '11': 'NBP', '12':'TR', '13':'CR', '15' : 'Loyalty Transfer', '18': 'Capital Wallet', '17': 'Praxis', '19' : 'LBT-Gatech', '20' : 'LBT-Payguru', '21': 'LBT-PayRetailer', '22': 'LBT-Rapid OZOW' , '26' : 'EuroBank', '27' : 'Performance Fee Subscription', '28' : 'InactivityFee', '29' : 'Paytoday', '30' : 'Reward', '31' : 'Alopay', '32' : 'Credit Transaction', '33' : 'PerformanceFee', '34' : 'Paymentasia', '36' : 'Refund',  '38' : 'PayPal', '39' : 'Qiwi', '40' : 'Credit Card - Wirecard', '41' : 'MoneyBookers', '42' : 'Teleingreso', '43' : 'AAAFX Promo Card',  '45' : 'AutoPay', '46' : 'Instant Deposit - Wirecard', '47' : 'EPS', '48' : 'DirectPay24',  '50' : 'iDeal','51' : 'Bitstamp',},
    depositMethods: {
        keys: ['All', 'Bank Cards', 'E-Wallet', 'Cryptos', 'Bank Wire'],
        methods: [
            {
                type: 'Bank Cards',
                minimumDeposit: 10,
                methodID: 5,
                id : 1,
                name: 'Credit Cards',
                image: 'dep1.png',
                region: 'World Wide',
                currencies: 'EUR, USD, CHF, GBP',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 10,
                methodID: 17,
                id : 4,
                name: 'Help 2 Pay',
                image: 'dep4.png',
                region: 'South East Asia',
                currencies: 'MY, TH, ID, PH, VN',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 10,
                methodID: 17,
                id : 5,
                name: 'Pay Retailers',
                image: 'dep5.png',
                region: 'Brazil, Mexico, Chile',
                currencies: 'BRL, MXN, CLP',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 10,
                methodID: 17,
                id : 2,
                name: 'Eurobank',
                image: 'dep2.png',
                region: 'World Wide',
                currencies: 'USD',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 10,
                methodID: 17,
                id : 3,
                name: 'Dixipay',
                image: 'dep3.png',
                region: 'Europe',
                currencies: 'EUR (Sepa Only)',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 10,
                methodID: 17,
                id : 6,
                name: 'Virtual Pay',
                image: 'dep6.png',
                region: 'East Africa',
                currencies: 'TAS, KES, MTN',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 10,
                methodID: 2,
                id : 7,
                name: 'Skrill',
                image: 'dep7.png',
                region: 'World Wide',
                currencies: 'EUR, USD, JPY, CHF, GBP, AUD',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 10,
                methodID: 3,
                id : 8,
                name: 'Neteller',
                image: 'dep8.png',
                region: 'World Wide',
                currencies: 'MY, TH, ID, PH, VN',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 10,
                methodID: 18,
                id : 9,
                name: 'Capital Wallet',
                image: 'capitalwallet-logo.png',
                region: 'World Wide',
                currencies: 'BTC',
                processingTime: '30 mins',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 10,
                methodID: 18,
                id : 9,
                name: 'Capital Wallet',
                image: 'capitalwallet-logo.png',
                region: 'World Wide',
                currencies: 'ETH',
                processingTime: '30 mins',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 10,
                methodID: 18,
                id : 9,
                name: 'Capital Wallet',
                image: 'capitalwallet-logo.png',
                region: 'World Wide',
                currencies: 'USDT',
                processingTime: '30 mins',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 10,
                methodID: 18,
                id : 9,
                name: 'Capital Wallet',
                image: 'capitalwallet-logo.png',
                region: 'World Wide',
                currencies: 'USDC',
                processingTime: '30 mins',
                action: ''
            },
            // {
            //     type: 'Cryptos',
            //     minimumDeposit: 10,
            //     methodID: 18,
            //     id : 9,
            //     name: 'Bitcoin',
            //     image: 'bitcoin-ico.png',
            //     region: 'World Wide',
            //     currencies: 'BTC',
            //     processingTime: '30 mins',
            //     action: ''
            // },
        ],
        testmethods: [
            {
                type: 'Bank Cards',
                minimumDeposit: 10,
                methodID: 5,
                id : 1,
                name: 'Credit Cards',
                image: 'dep1.png',
                region: 'World Wide',
                currencies: 'EUR, USD, CHF, GBP',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 10,
                methodID: 17,
                id : 2,
                name: 'Eurobank',
                image: 'dep2.png',
                region: 'World Wide',
                currencies: 'USD',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 10,
                methodID: 17,
                id : 3,
                name: 'Dixipay',
                image: 'dep3.png',
                region: 'Europe',
                currencies: 'EUR (Sepa Only)',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 10,
                methodID: 17,
                id : 4,
                name: 'Help 2 Pay',
                image: 'dep4.png',
                region: 'South East Asia',
                currencies: 'MY, TH, ID, PH, VN',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 10,
                methodID: 17,
                id : 5,
                name: 'Pay Retailers',
                image: 'dep5.png',
                region: 'Brazil, Mexico, Chile',
                currencies: 'BRL, MXN, CLP',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 10,
                methodID: 17,
                id : 6,
                name: 'Virtual Pay',
                image: 'dep6.png',
                region: 'East Africa',
                currencies: 'TAS, KES, MTN',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 10,
                methodID: 2,
                id : 7,
                name: 'Skrill',
                image: 'dep7.png',
                region: 'World Wide',
                currencies: 'EUR, USD, JPY, CHF, GBP, AUD',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 10,
                methodID: 3,
                id : 8,
                name: 'Neteller',
                image: 'dep8.png',
                region: 'World Wide',
                currencies: 'MY, TH, ID, PH, VN',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 10,
                methodID: 18,
                id : 9,
                name: 'Capital Wallet',
                image: 'capitalwallet-logo.png',
                region: 'World Wide',
                currencies: 'BTC',
                processingTime: '30 mins',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 10,
                methodID: 18,
                id : 9,
                name: 'Capital Wallet',
                image: 'capitalwallet-logo.png',
                region: 'World Wide',
                currencies: 'ETH',
                processingTime: '30 mins',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 10,
                methodID: 18,
                id : 9,
                name: 'Capital Wallet',
                image: 'capitalwallet-logo.png',
                region: 'World Wide',
                currencies: 'USDT',
                processingTime: '30 mins',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 10,
                methodID: 18,
                id : 9,
                name: 'Capital Wallet',
                image: 'capitalwallet-logo.png',
                region: 'World Wide',
                currencies: 'USDC',
                processingTime: '30 mins',
                action: ''
            },
            // {
            //     type: 'Cryptos',
            //     minimumDeposit: 10,
            //     methodID: 18,
            //     id : 9,
            //     name: 'Bitcoin',
            //     image: 'bitcoin-ico.png',
            //     region: 'World Wide',
            //     currencies: 'BTC',
            //     processingTime: '30 mins',
            //     action: ''
            // },
        ]
    },
    timezones: [
        { value: "-12:00", label: '(GMT -12:00) Eniwetok, Kwajalein' },
        { value: "-11:00", label: '(GMT -11:00) Midway Island, Samoa' },
        { value: "-10:00", label: '(GMT -10:00) Hawaii' },
        { value: "-09:50", label: '(GMT -9:30) Taiohae' },
        { value: "-09:00", label: '(GMT -9:00) Alaska' },
        { value: "-08:00", label: '(GMT -8:00) Pacific Time (US &amp; Canada)' },
        { value: "-07:00", label: '(GMT -7:00) Mountain Time (US &amp; Canada)' },
        { value: "-06:00", label: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City' },
        { value: "-05:00", label: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima' },
        { value: "-04:50", label: '(GMT -4:30) Caracas' },
        { value: "-04:00", label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz' },
        { value: "-03:50", label: '(GMT -3:30) Newfoundland' },
        { value: "-03:00", label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
        { value: "-02:00", label: '(GMT -2:00) Mid-Atlantic' },
        { value: "-01:00", label: '(GMT -1:00) Azores, Cape Verde Islands' },
        { value: "+00:00", label: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
        { value: "+01:00", label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
        { value: "+02:00", label: '(GMT +2:00) Kaliningrad, South Africa' },
        { value: "+03:00", label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
        { value: "+03:50", label: '(GMT +3:30) Tehran' },
        { value: "+04:00", label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
        { value: "+04:50", label: '(GMT +4:30) Kabul' },
        { value: "+05:00", label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
        { value: "+05:50", label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
        { value: "+05:75", label: '(GMT +5:45) Kathmandu, Pokhara' },
        { value: "+06:00", label: '(GMT +6:00) Almaty, Dhaka, Colombo' },
        { value: "+06:50", label: '(GMT +6:30) Yangon, Mandalay' },
        { value: "+07:00", label: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
        { value: "+08:00", label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
        { value: "+08:75", label: '(GMT +8:45) Eucla' },
        { value: "+09:00", label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
        { value: "+09:50", label: '(GMT +9:30) Adelaide, Darwin' },
        { value: "+10:00", label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
        { value: "+10:50", label: '(GMT +10:30) Lord Howe Island' },
        { value: "+11:00", label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
        { value: "+11:50", label: '(GMT +11:30) Norfolk Island' },
        { value: "+12:00", label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
        { value: "+12:75", label: '(GMT +12:45) Chatham Islands' },
        { value: "+13:00", label: '(GMT +13:00) Apia, Nukualofa' },
        { value: "+14:00", label: '(GMT +14:00) Line Islands, Tokelau' },

    ],
    european_countries_list: {
        "AT": 'Austria',
        "BE": 'Belgium',
        "BG": 'Bulgaria',
        "CY": 'Cyprus',
        "CZ": 'Czechia',
        "DE": 'Germany',
        "DK": 'Denmark',
        "EE": 'Estonia',
        "ES": 'Spain',
        "FI": 'Finland',
        "FR": 'France',
        "GR": 'Greece',
        "HR": 'Croatia',
        "HU": 'Hungary',
        "IE": 'Ireland',
        "IT": 'Italy',
        "LT": 'Lithuania',
        "LU": 'Luxembourg',
        "LV": 'Latvia',
        "MT": 'Malta',
        "NL": 'Netherlands',
        "PO": 'Poland',
        "PT": 'Portugal',
        "RO": 'Romania',
        "SE": 'Sweden',
        "SI": 'Slovenia',
        "SK": 'Slovakia',
        "GB": 'United Kingdom'
    },
    fileNames:[
        { name:'Passport',value:'Passport',type:'Proof_of_Identity'},
        { name:'Drivers_License_BACK',value:'Drivers License Back',type:'Proof_of_Identity'},
        { name:'Drivers_License_FRONT',value:'Drivers License Front',type:'Proof_of_Identity'},
        { name:'Government_Issued_Photo_ID_BACK',value:'Government Issued Photo ID Back',type:'Proof_of_Identity'},
        { name:'Government_Issued_Photo_ID_FRONT',value:'Government Issued Photo ID Front',type:'Proof_of_Identity'},
        { name:'Bank_Credit_Card_Statement',value:'Bank Statement/Credit Card Statement',type:'Proof_of_Residence'},
        { name:'Utility_Bill',value:'Utility Bill',type:'Proof_of_Residence'},
        { name:'Payroll_Statement',value:'Payroll Statement',type:'Proof_of_Residence'},
        { name:'Tax_Document',value:'Tax Document',type:'Proof_of_Residence'},
        { name:'Photo_Verification',value:'Liveness/Selfie',type:'Photo_Verification'}
    ],
    questionList:{
        "1":{
            "question" : "Total Estimated Net Worth (Assets - Liabilities)",
            "type" : "Assets Liabilities",
            "multiplier" : 5,
            "answers_list" : {
                "1" : { "answer" : "less than $25000", "grading" : 1, "score" : 5, "id" : 1 },
                "2" : { "answer" : "$25001 - $50000", "grading" : 2, "score" : 10, "id" : 2 },
                "3" : { "answer" : "$50001 - $100000", "grading" : 3, "score" : 15, "id" : 3 },
                "4" : { "answer" : "$100001 - $500000", "grading" : 4, "score" : 20, "id" : 4 },
                "5" : { "answer" : "$500001 - $1000000", "grading" : 5, "score" : 25, "id" : 5 },
                "6" : { "answer" : "more than $1000000", "grading" : 5, "score" : 25, "id" : 6 },
            }
        },
        "2":{
            "question" : "Total Estimated Annual Income",
            "type" : "Annual Income",
            "multiplier" : 5,
            "answers_list" : {
                "1" : { "answer" : "less than $25000", "grading" : 1, "score" : 5, "id" : 7 },
                "2" : { "answer" : "$25001 - $50000", "grading" : 2, "score" : 10, "id" : 8 },
                "3" : { "answer" : "$50001 - $100000", "grading" : 3, "score" : 15, "id" : 9 },
                "4" : { "answer" : "$100001 - $500000", "grading" : 4, "score" : 20, "id" : 10 },
                "5" : { "answer" : "$500001 - $1000000", "grading" : 5, "score" : 25, "id" : 11 },
                "6" : { "answer" : "more than $1000000", "grading" : 5, "score" : 25, "id" : 12 },
            }
        },
        "3":{
            "question" : "Liabilities Compared to your regular income",
            "type" : "Liabilities",
            "multiplier" : 4,
            "answers_list" : {
                "1" : { "answer" : "25%", "grading" : 4, "score" : 16, "id" : 13},
                "2" : { "answer" : "50%", "grading" : 3, "score" : 12, "id" : 14},
                "3" : { "answer" : "75%", "grading" : 1, "score" : 4, "id" : 15},
            }
        },
        "4":{
            "question" : "Over the past 3 years,to what extend have you traded Forex",
            "type" : "Traded Forex",
            "multiplier" : 4,
            "answers_list" : {
                "1" : { "answer" : "Frequently", "grading" : 5, "score" : 20, "id" : 16},
                "2" : { "answer" : "Sometimes", "grading" : 3, "score" : 12, "id" : 17},
                "3" : { "answer" : "Rarely", "grading" : 1, "score" : 4, "id" : 18},
                "4" : { "answer" : "Never", "grading" : 0, "score" : 0, "id" : 19},
            }
        },
        "5":{
            "question" : "Over the past 3 years,to what extend have you traded Derivatives (Futures, Warrants or Options)",
            "type" : "Traded Derivatives",
            "multiplier" : 4,
            "answers_list" : {
                "1" : { "answer" : "Frequently", "grading" : 5, "score" : 20, "id" : 20},
                "2" : { "answer" : "Sometimes", "grading" : 3, "score" : 12, "id" : 21},
                "3" : { "answer" : "Rarely", "grading" : 1, "score" : 4, "id" : 22},
                "4" : { "answer" : "Never", "grading" : 0, "score" : 0, "id" : 23},
            }
        },
        "6":{
            "question" : "Over the past 3 years,to what extend have you traded CFDs",
            "type" : "Traded CFDs",
            "multiplier" : 4,
            "answers_list" : {
                "1" : { "answer" : "Frequently", "grading" : 5, "score" : 20, "id" : 24},
                "2" : { "answer" : "Sometimes", "grading" : 3, "score" : 12, "id" : 25},
                "3" : { "answer" : "Rarely", "grading" : 1, "score" : 4, "id" : 26},
                "4" : { "answer" : "Never", "grading" : 0, "score" : 0, "id" : 27},
            }
        },
        "7":{
            "question" : "Over the past 3 years,to what extend have you traded Shares and bonds",
            "type" : "Traded Shares and bonds",
            "multiplier" : 4,
            "answers_list" : {
                "1" : { "answer" : "Frequently", "grading" : 5, "score" : 20, "id" : 28},
                "2" : { "answer" : "Sometimes", "grading" : 3, "score" : 12, "id" : 29},
                "3" : { "answer" : "Rarely", "grading" : 1, "score" : 4, "id" : 30},
                "4" : { "answer" : "Never", "grading" : 0, "score" : 0, "id" : 31},
            }
        },
        "8":{
            "question" : "Over the past 3 years,to what extend have you traded Forex, Binaries, CFDs via auto-trading platforms",
            "type" : "Traded via auto-trading platforms",
            "multiplier" : 4,
            "answers_list" : {
                "1" : { "answer" : "Frequently", "grading" : 5, "score" : 20, "id" : 32},
                "2" : { "answer" : "Sometimes", "grading" : 3, "score" : 12, "id" : 33},
                "3" : { "answer" : "Rarely", "grading" : 1, "score" : 4, "id" : 34},
                "4" : { "answer" : "Never", "grading" : 0, "score" : 0, "id" : 35},
            }
        },
        "9":{
            "question" : "Prior work experience",
            "type" : "Work experience",
            "multiplier" : 3,
            "answers_list" : {
                "1" : { "answer" : "Yes - Working in Financial Institution", "grading" : 3, "score" : 9, "id" : 36},
                "2" : { "answer" : "Yes - Academic background", "grading" : 4, "score" : 12, "id" : 37},
                "3" : { "answer" : "Yes - All the above", "grading" : 5, "score" : 15, "id" : 38},
                "4" : { "answer" : "No ", "grading" : 1, "score" : 3, "id" : 39},
            }
        },
        "10":{
            "question" : "Investment objections",
            "type" : "Investment objections",
            "multiplier" : 3,
            "answers_list" : {
                "1" : { "answer" : "Speculative", "grading" : 5, "score" : 20, "id" : 40},
                "2" : { "answer" : "Hedging", "grading" : 5, "score" : 20, "id" : 41},
                "3" : { "answer" : "Investment  ", "grading" : 4, "score" : 16, "id" : 42},
                "4" : { "answer" : "Risk Management", "grading" : 3, "score" : 12, "id" : 43},
                "5" : { "answer" : "Diversification", "grading" : 2, "score" : 8, "id" : 44},
                "6" : { "answer" : "Other (if other, please specify)", "grading" : 1, "score" : 4, "id" : 45},
            }
        },
    }
}