<template>
  <div class="login-page register-page">
    <div id="">
      <div class="login-page" :class="{ minHeight: store.showOTP }">
        <div class="register-holder center-div wow fadeIn">
          <router-link to="/login">
            <img width="145" src="assets/images/logo.svg" alt="" />
          </router-link>
          <br /><br />
          <div class="form-holder">
            <div class="form-group" v-if="store.showOTP">
              <h4>
                We've sent an OTP to {{ form.email }}. Please enter it to verify
                and complete your registration.
              </h4>
              <Form @submit="verify">
                <ul class="clearfix">
                  <li class="">
                    <label for=""
                      >OTP <span style="color: #d43737">*</span></label
                    >
                    <Field
                      class="form-control"
                      name="otp"
                      rules="required|numeric|min:6|max:6"
                      v-model="otp"
                    />
                    <ErrorMessage name="otp" />
                  </li>
                </ul>
                <div class="btn-holder t-center">
                  <button class="btn btn-blue popup-link-holder">Submit</button>
                </div>
              </Form>
            </div>
            <Form v-else @submit="register">
              <h3 class="t-center">Register</h3>
              <hr />
              <ul class="clearfix">
                <!-- <li class="full-width f-left noColor">
                  <label for="">Account Type</label>
                  <Field
                    class="form-control"
                    name="account type"
                    v-model="form.account_type"
                    rules="required"
                    as="select"
                  >
                    <option value="" disabled>Select account type</option>
                    <option
                      v-for="type in static_vars.registrationType"
                      :value="type.value"
                      :key="type.value"
                    >
                      {{ type.label }}
                    </option>
                  </Field>
                  <ErrorMessage name="account type" />
                </li> -->
                <li class="full-width f-left">
                  <label for="">First name</label>
                  <Field
                    class="form-control"
                    name="first name"
                    autofill="off"
                    autocomplete="username"
                    v-model="form.firstname"
                    rules="required"
                  />
                  <ErrorMessage name="first name" />
                </li>
                <li class="full-width f-left">
                  <label for="">Last name</label>
                  <Field
                    class="form-control"
                    name="last name"
                    autofill="off"
                    autocomplete="username"
                    v-model="form.lastname"
                    rules="required"
                  />
                  <ErrorMessage name="last name" />
                </li>
                <li class="full-width f-left">
                  <label for="">Email</label>
                  <Field
                    class="form-control"
                    name="email"
                    autofill="off"
                    autocomplete="username"
                    v-model="form.email"
                    rules="required|email"
                  />
                  <ErrorMessage name="email" />
                </li>
                <li class="full-width f-left position-relative">
                  <label for="">Password</label>
                  <div style="position: relative">
                    <Field
                      :type="inputtype"
                      class="form-control"
                      autofill="off"
                      autocomplete="new-password"
                      name="password"
                      v-model="form.password"
                      rules="required|min:8"
                      @focusin="showPassDiv = true"
                      @focusout="showPassDiv = false"
                    />
                    <vue-feather
                      :type="iconType"
                      class="toggle-password"
                      @click.prevent="switchVisibility"
                    ></vue-feather>
                  </div>
                  <ErrorMessage name="password" />

                  <div id="pr-box" class="light" v-show="showPassDiv">
                    <i></i>
                    <div id="pr-box-inner">
                      <p>
                        The minimum password length is 8 characters and must
                        contain at least 1 lowercase letter, 1 capital letter 1
                        number and 1 special character.
                      </p>
                      <ul>
                        <li class="pr-numcharacters">
                          <span
                            :class="form.password.length >= 8 ? 'pr-ok' : ''"
                          ></span
                          ># of characters
                        </li>
                        <li class="pr-useLowercase">
                          <span :class="lowercaseOK ? 'pr-ok' : ''"></span
                          >Lowercase letter
                        </li>
                        <li class="pr-useUppercase">
                          <span :class="uppercaseOk ? 'pr-ok' : ''"></span
                          >Capital letter
                        </li>
                        <li class="pr-useNumbers">
                          <span :class="numberOk ? 'pr-ok' : ''"></span>Number
                        </li>
                        <li class="pr-useSpecial">
                          <span :class="SpecialCharOk ? 'pr-ok' : ''"></span
                          >Special character
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="full-width f-left">
                  <label for="">Confirm Password</label>
                  <div style="position: relative">
                    <Field
                      :type="inputtype1"
                      class="form-control"
                      name="confirm password"
                      autocomplete="new-password"
                      v-model="form.confirmpassword"
                      rules="required|confirmed:@password"
                    />
                    <vue-feather
                      :type="iconType1"
                      class="toggle-password"
                      @click.prevent="switchVisibility1"
                    ></vue-feather>
                  </div>
                  <ErrorMessage name="confirm password" />
                </li>
                <li class="full-width f-left noColor">
                  <label for="">Country</label>
                  <Field
                    class="form-control"
                    name="country"
                    v-model="form.residence_country"
                    rules="required"
                    as="select"
                    @change="changecountry"
                  >
                    <option value="" disabled>Select residence country</option>
                    <option
                      v-for="country in store.countries"
                      :value="country.iso2"
                      :key="country.iso2"
                    >
                      {{ country.name }}
                    </option>
                    <!-- <v-select
                      v-bind="field"
                      :reduce="(country) => country.iso2"
                      v-model="form.residence_country"
                      :options="store.countries"
                      label="name"
                    >
                    </v-select> -->
                  </Field>
                  <ErrorMessage name="country" />
                </li>
                <li class="full-width f-left" v-show="form.residence_country == '' || (EUContries.includes(form.residence_country))">
                  <label for="">Phone Number</label>
                  <Field
                    v-slot="{ field }"
                    name="Phone Number"
                    rules="required"
                    v-model="form.mobile"
                  >
                    <vue-tel-input
                      v-bind="field"
                      v-model="phoneCode"
                      @country-changed="GetCode"
                      ref="phone"
                    ></vue-tel-input>

              </Field>
                  <ErrorMessage name="Phone Number" />
                </li>
                <li class="full-width f-left" v-show="form.residence_country == '' || (EUContries.includes(form.residence_country))">
                  <label class="customCheckBox">
                    <!--  I have read, understood and accepted the
                      <a href="#">Terms and Conditions</a> -->
                      <p class="inlinContent">I have read and I accept the <a href="#" target="_blank">AAAFx Retail Client Agreement</a>, the <a href="/policies/ClientAssets.pdf" target="_blank">AAAFx Client assets</a>, the <a href="/policies/ConflictsOfInterestPolicy.pdf" target="_blank">AAAFx Conflicts of Interest Policy</a>, the <a href="/policies/ExecutionPolicy.pdf" target="_blank">AAAFx Execution policy</a>, the <a href="/policies/InvestorCompensationScheme.pdf" target="_blank">AAAFx Investor Compensation Scheme</a>, the <router-link to="/privacy" target="_blank">AAAFx Privacy Policy</router-link>, the <a href="/policies/RiskDisclaimer.pdf" target="_blank">AAAFx Risk Disclaimer</a>, the <a href="/policies/MarketDataAttendum.pdf" target="_blank">AAAFx Market Data Attendum</a> and the <a href="/policies/RefundPolicy.pdf" target="_blank">AAAFx Refund Policy</a>. I also agree to be contacted by one of your customer service representatives.</p>
                    <input
                      type="checkbox"
                      v-model="form.accept"
                      :true-value="true"
                      :false-value="false"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span v-if="submitted && !form.accept"
                    >Please accept the terms and conditions</span
                  >
                </li>
              </ul>
              <div class="errormsg" v-show="form.residence_country != '' && !(EUContries.includes(form.residence_country))" ref="showmsg">
                <p>Given your residency you ‘ll be onboarded with Alpha Global Limited (SVG). You can view  available protections and terms & conditions on our website. By clicking <a :href="globalclient">continue</a> you agree to  the opening of your account with Alpha Global Limited (SVG).</p>
              </div>
              <div class="btn-holder" v-show="form.residence_country == '' || (EUContries.includes(form.residence_country))">
                <button type="submit" class="btn btn-yellow popup-link-holder">
                  REGISTER
                </button>
              </div>
            </Form>
          </div>
          <div class="links">
            <p>
              Already have an account?
              <router-link to="/login">Login</router-link>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  mounted() {
    this.store.getCountriesList();
    this.EUContries = Object.keys(this.static_vars.european_countries_list).map(
      (val) => val
    );
  },
  data() {
    return {
      otp: "",
      form: {
        email: "",
        password: "",
        confirmpassword: "",
        residence_country: "",
        phone: "",
        accept: false,
        rm_id: "0",
        IBREFERLINK: "",
        account_type: "0",
        admin_id: "0",
        captchaToken: "",
        ip_country: "IN",
        firstname : "",
        lastname : "",
      },
      submitted: false,
      showpassword: false,
      iconType: "eye-off",
      inputtype: "password",
      showpassword1: false,
      iconType1: "eye-off",
      inputtype1: "password",
      showPassDiv: false,
      lowercaseOK: false,
      uppercaseOk: false,
      numberOk: false,
      SpecialCharOk: false,
      phoneCode: "",
      phoneNo: "",
      EUContries: [],
    };
  },
  watch: {
    "form.password": function (val) {
      let lowerCase = new RegExp("[a-z]"),
        upperCase = new RegExp("[A-Z]"),
        numbers = new RegExp("[0-9]"),
        specialcharacter = new RegExp("[!,%,&,@,#,$,^,*,?,_,~]");
      if (val.match(lowerCase)) {
        this.lowercaseOK = true;
      } else {
        this.lowercaseOK = false;
      }
      if (val.match(upperCase)) {
        this.uppercaseOk = true;
      } else {
        this.uppercaseOk = false;
      }
      if (val.match(numbers)) {
        this.numberOk = true;
      } else {
        this.numberOk = false;
      }
      if (val.match(specialcharacter)) {
        this.SpecialCharOk = true;
      } else {
        this.SpecialCharOk = false;
      }
      if (
        this.lowercaseOK &&
        this.uppercaseOk &&
        this.numberOk &&
        this.SpecialCharOk &&
        val.length >= 8
      ) {
        this.showPassDiv = false;
      }
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    changecountry(e) {
      this.$refs.phone.choose(e.target.value);
    },
    onInput(e) {
      this.phoneNo = e.target.value;
    },
    GetCode(e) {
      this.phoneCode = e.dialCode;
    },
    register() {
      this.submitted = true;
      this.account_type = "0";
      this.form.phone = `${this.phoneCode} ${this.phoneNo}`;
      if (this.form.accept) {
        this.submitted = false;

        //===========in use==============
        if(this.$cookies.get('refLink') && this.$cookies.get('refRm')){
            this.form.rm_id = this.$cookies.get('refRm')
            this.form.admin_id = this.$cookies.get('refLink')
        }else if(this.$cookies.get('IBREFERLINK')){
            let decrpyLinRm = window.atob(this.$cookies.get('IBREFERLINK').toString()) ; 
            let decrpyLinRm1 = decrpyLinRm.split('||')
            this.$cookies.set("refLink",window.btoa(decrpyLinRm1[0]),"4y", null, process.env.VUE_APP_cookieSaveUrl);
            this.$cookies.set("refRm",window.btoa(decrpyLinRm1[1]),"4y", null, process.env.VUE_APP_cookieSaveUrl);
            this.form.rm_id = this.$cookies.get('refRm')
            this.form.admin_id = this.$cookies.get('refLink')
        } 

        this.store.register(this.form, true);
      } else {
        return;
      }
    },
    switchVisibility() {
      this.showpassword = !this.showpassword;
      if (this.showpassword) {
        this.inputtype = "text";
        this.iconType = "eye";
      } else {
        this.inputtype = "password";
        this.iconType = "eye-off";
      }
    },
    switchVisibility1() {
      this.showpassword1 = !this.showpassword1;
      if (this.showpassword1) {
        this.inputtype1 = "text";
        this.iconType1 = "eye";
      } else {
        this.inputtype1 = "password";
        this.iconType1 = "eye-off";
      }
    },
    verify() {
      let formData = {
        otp: this.otp,
        ID: this.store.trans_id,
        email: this.form.email,
        password: this.form.password,
      };
      //===========in use==============
      if(this.$cookies.get('refLink') && this.$cookies.get('refRm')){
          formData.rm_id = this.$cookies.get('refRm')
          formData.admin_id = this.$cookies.get('refLink')
      }else if(this.$cookies.get('IBREFERLINK')){
          let decrpyLinRm = window.atob(this.$cookies.get('IBREFERLINK').toString()) ; 
          let decrpyLinRm1 = decrpyLinRm.split('||')
          this.$cookies.set("refLink",window.btoa(decrpyLinRm1[0]),"4y", null, process.env.VUE_APP_cookieSaveUrl);
          this.$cookies.set("refRm",window.btoa(decrpyLinRm1[1]),"4y", null, process.env.VUE_APP_cookieSaveUrl);
          formData.rm_id = this.$cookies.get('refRm')
          formData.admin_id = this.$cookies.get('refLink')
      }
      this.store.verifyRegistration(formData, true);
    },
    selectCountry(e) {
      this.form.residence_country = e.iso2;
    },
  },
  created(){
       //=============in use===================
       //console.log("svae link", process.env.VUE_APP_cookieSaveUrl)
      if(this.$route.query.refLink && this.$route.query.refRm){
          this.$cookies.set("refLink",this.$route.query.refLink,"4y", null, process.env.VUE_APP_cookieSaveUrl);
          this.$cookies.set("refRm",this.$route.query.refRm,"4y", null, process.env.VUE_APP_cookieSaveUrl);
          this.$router.replace({query: null})
      }else if(this.$cookies.get('IBREFERLINK')){
          let decrpyLinRm = window.atob(this.$cookies.get('IBREFERLINK').toString()) ; 
          let decrpyLinRm1 = decrpyLinRm.split('||')
          this.$cookies.set("refLink",window.btoa(decrpyLinRm1[0]),"4y", null, process.env.VUE_APP_cookieSaveUrl);
          this.$cookies.set("refRm",window.btoa(decrpyLinRm1[1]),"4y", null, process.env.VUE_APP_cookieSaveUrl);
      }
  },
};
</script>
<style scoped>
:deep() .vs__selected-options {
  flex-wrap: nowrap !important;
}
:deep() .form-holder ul li.noColor input.vs__search {
  border-color: transparent;
}
:deep() .vs__dropdown-toggle {
  border: 1px solid #28485e;
}
.minHeight {
  min-height: 100vh;
}
</style>
