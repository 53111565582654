<style>
.stepsBar {
  display: flex;
  justify-content: space-between;
  max-width: 70%;
  margin: 0 auto;
  margin-top: 20px;
}
.steps {
  color: #56bae7;
  align-items: center;
  display: flex;
  flex-direction: column;
}
p.active {
  font-weight: bold;
}
hr.verify {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #cfcfcf;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}
a.stepper-item {
  color: #073763;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #ccc;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
.rejectionBlock {
  text-align: center;
  background-color: #fff;
  padding: 5%;
}
.rejectionBlock img {
  width: 20%;
}
.rejectionBlock h4 {
  font-size: 26px;
  margin-top: 40px;
  margin-bottom: 50px;
}
.rejectionBlock a {
  font-size: 20px;
}
</style>
<template>
  <div class="deposit-live profile-page">
    <div class="stepper-wrapper">
      <a @click="change_step(1)" class="stepper-item" :class=" store.liveAccounts.length || hasLiveAccount() ? 'completed' : '' ">
        <div class="step-counter">
          <i v-show="store.liveAccounts.length > 0 || hasLiveAccount()" class="far fa-check-circle fa-3x"></i>
          <i v-show=" store.liveAccounts.length==0 && !hasLiveAccount()" class="far fa-circle fa-3x"></i>
        </div>
        <div class="step-name"><p :class="step==1 ? 'active' : ''">Account Creation</p></div>
      </a>
      <a @click="change_step(2)" class="stepper-item" :class="sumsubVerified || isVerified() ? 'completed' : ''">
        <div class="step-counter">
          <i v-show="sumsubVerified || isVerified()" class="far fa-check-circle fa-3x"></i>
          <i v-show="!sumsubVerified && !isVerified()" class="far fa-circle fa-3x"></i>
        </div>
        <div class="step-name"><p :class="step==2 ? 'active' : ''">Verification</p></div>
      </a>
      <a @click="()=> change_step(3)" class="stepper-item" :class="isQuestionAnswer() ? 'completed' : ''">
        <div class="step-counter"><i class="far fa-3x" :class="(isQuestionAnswer())?'fa-check-circle':'fa-circle'"></i></div>
        <div class="step-name"><p :class="step==3 ? 'active' : ''">Questionnaire</p></div>
      </a>
      <a @click="change_step(4)" class="stepper-item" :class="hasDeposit() ? 'completed' : ''">
        <div class="step-counter"><i v-show="hasDeposit()" class="far fa-check-circle fa-3x"></i> <i v-show="!hasDeposit()" class="far fa-circle fa-3x"></i></div>
        <div class="step-name"><p :class="step==3 ? 'active' : ''">Deposit</p></div>
      </a>
    </div>
    <hr class="verify" />
    <div style="min-height: 500px; justify-content: center; align-items: center; max-width: 65%; margin: 0 auto;">
      <div v-show="step==1">
        <div class="main-content f-left">
          <Form @submit="createAccount">
            <div class="content-holder">
              <div class="row-sec">
                <div class="form-holder wow fadeInLeft">
                  <ul class="clearfix">
                    <li>
                      <label for="">Select Platform</label>
                      <Field class="form-control" as="select" name="platform" rules="required" v-model="form.platform" @change=" form.currency ? (form.currency='') : null; form.account_type ? (form.account_type='') : null; ">
                        <option value="" disabled>Select Platform</option> <option v-for="platform of availablePlatforms" :value="platform.value" :key="platform.value">{{platform.label}}</option>
                      </Field>
                      <ErrorMessage name="platform" />
                    </li>
                    <li>
                      <label for="">Select Account Type</label>
                      <Field class="form-control" as="select" name="type" rules="required" v-model="form.account_type">
                        <option value="" disabled>Select Account Type</option> <option v-for="account of availableAccountTypes" :value="account.value" :key="account.value">{{account.label}}</option>
                      </Field>
                      <ErrorMessage name="type" />
                    </li>
                    <li v-if=" form.account_type !='ISL' && form.account_type !='ZULU' ">
                      <label for="">Select Leverage</label>
                      <Field class="form-control" as="select" name="leverage" rules="required" v-model="form.leverage">
                        <option value="" disabled>Select Leverage</option> <option v-for="leverage of static_vars.leverages" :value="leverage.value" :key="leverage.value">{{leverage.label}}</option>
                      </Field>
                      <ErrorMessage name="leverage" />
                    </li>
                    <li>
                      <label for="">Select Account Base currency</label>
                      <Field class="form-control" as="select" name="currency" rules="required" v-model="form.currency">
                        <option value="" disabled>Select Currency</option> <option v-for="(value, key) in currencyList()" :value="key" :key="value">{{key}}</option>
                      </Field>
                      <ErrorMessage name="currency" />
                    </li>
                    <li>
                      <label for="">Password</label>
                      <Field class="form-control" name="password" type="password" rules="required|min:5" v-model="form.password" maxlength="15" @focusin="showPassDiv=true" @focusout="showPassDiv=false" />
                      <ErrorMessage name="password" />
                      <div id="pr-box" class="light" v-show="showPassDiv">
                        <i></i>
                        <div id="pr-box-inner">
                          <p>The minimum password length is 5 characters and must contain at least 1 lowercase letter, 1 capital letter 1 number and 1 special character.</p>
                          <ul>
                            <li class="pr-numcharacters"><span :class="form.password.length >=5 ? 'pr-ok' : ''"></span># of characters</li>
                            <li class="pr-useLowercase"><span :class="lowercaseOK ? 'pr-ok' : ''"></span>Lowercase letter</li>
                            <li class="pr-useUppercase"><span :class="uppercaseOk ? 'pr-ok' : ''"></span>Capital letter</li>
                            <li class="pr-useNumbers"><span :class="numberOk ? 'pr-ok' : ''"></span>Number</li>
                            <li class="pr-useSpecial"><span :class="SpecialCharOk ? 'pr-ok' : ''"></span>Special character</li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <label for="">Confirm Password</label>
                      <Field class="form-control" name="Confirm Password" type="password" rules="required|min:5|confirmed:@password" v-model="form.confirmPassword" maxlength="15" />
                      <ErrorMessage name="Confirm Password" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="btn-holder t-center"><button type="submit" class="btn btn-yellow popup-link-holder">Create Account</button></div>
          </Form>
        </div>
      </div>
      <div v-show="step==2">
        <div v-show="!showRejectionBlock" id="sumsub-websdk-container"></div>
        <div class="rejectionBlock" v-show="showRejectionBlock">
          <img src="assets/images/rejected.svg" />
          <h4>
            The documents that you submitted have been rejected by automatic verification process. A ticket regarding the same has been created where you can reupload your documents for us to manually verify the documents if you wish so.
          </h4>
          <div><router-link to="/open-tickets" class="btn btn-blue">View Ticket</router-link></div>
        </div>
      </div>
      <div v-show="step==3"><Questions /></div>
      <div v-show="step==4"><Deposit /></div>
    </div>
  </div>
</template>


<script>
import { useMainStore } from "@/store";
import snsWebSdk from "@sumsub/websdk";
import Questions from "@/views/questions/index";
import Deposit from "@/views/funds/deposit";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      ipvStarted: false,
      step: 1,
      showRejectionBlock: false,
      sumsubVerified: false,
      form: {
        account_type: "",
        demo_live: "1",
        platform: "MT4",
        leverage: "",
        currency: "",
        password: "",
        confirmPassword: "",
        verificationSteps: true,
      },
      showPassDiv: false,
      lowercaseOK: false,
      uppercaseOk: false,
      numberOk: false,
      SpecialCharOk: false
    };
  },
  mounted() {
     this.showRejectionBlock = false;
    if (this.isQuestionAnswer()) {
      this.changeStep(4);
    }else if (this.isVerified()) {
      this.changeStep(3);
    } else if (this.hasLiveAccount()) {
      this.changeStep(2);
    } else if (this.hasDeposit()) {
      this.changeStep(1);
    }
  },
  watch: {
    "form.password": function (val) {
      let lowerCase = new RegExp("[a-z]"),
        upperCase = new RegExp("[A-Z]"),
        numbers = new RegExp("[0-9]"),
        specialcharacter = new RegExp("[!,%,&,@,#,$,^,*,?,_,~]");
      if (val.match(lowerCase)) {
        this.lowercaseOK = true;
      } else {
        this.lowercaseOK = false;
      }
      if (val.match(upperCase)) {
        this.uppercaseOk = true;
      } else {
        this.uppercaseOk = false;
      }
      if (val.match(numbers)) {
        this.numberOk = true;
      } else {
        this.numberOk = false;
      }
      if (val.match(specialcharacter)) {
        this.SpecialCharOk = true;
      } else {
        this.SpecialCharOk = false;
      }
      if (
        this.lowercaseOK &&
        this.uppercaseOk &&
        this.numberOk &&
        this.SpecialCharOk &&
        val.length >= 5
      ) {
        this.showPassDiv = false;
      }
    },
  },
  computed: {
    availablePlatforms() {
      if (this.form.demo_live == "1") {
        return this.static_vars.platforms;
      } else {
        return this.static_vars.platforms.filter((i) => i.value != "ZTP");
      }
    },
    availableAccountTypes() {
      if (this.form.platform == "MT5") {
        return this.static_vars.accountTypes.filter(
          (i) => i.value != "ISL" && i.value != "ZULU"
        );
      } else {
        return this.static_vars.accountTypes;
      }
      /*if (this.form.platform == "ZTP") {
        return this.static_vars.accountTypes.filter(
          (i) => i.value != "ECN" && i.value != "ECNP"
        );
      } else {
        return this.static_vars.accountTypes.filter(
          (i) => i.value == "ECN" || i.value == "ECNP"
        );
      }*/
    },
  },
  components: {
    Deposit,
    Questions,
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    currencyList() {
      if (this.form.platform == "MT4") {
        return this.static_vars.currency_mt4;
      } else {
        return this.static_vars.currency;
      }
    },
    hasLiveAccount() {
      if (this.store.liveAccounts.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isVerified() {
      if (parseInt(this.store.user.verified_status)) {
        return true;
      } else {
        return false;
      }
    },
    hasDeposit() {
      if (this.store.depositsList.records.length) {
        return true;
      } else {
        return false;
      }
    },
    change_step(step) {
      if(this.step > step){
        this.changeStep(step)
      }
    },
    changeStep(step) {
      let score = (this.store.user.profile && this.store.user.profile.risk_score)?parseInt(this.store.user.profile.risk_score):0
      let riskscore_agreed = (this.store.user.profile && this.store.user.profile.riskscore_agreed)?parseInt(this.store.user.profile.riskscore_agreed):0
      if (step == 1 && !this.hasLiveAccount()) {
        this.step = step;
      }
      if (step == 2 && !this.isVerified()) {
        this.step = step;
        setTimeout(() => {
          this.startIpvNow();
        }, 300);
      }
      if (step == 3 && !this.isQuestionAnswer()) {
        this.step = step;
      }
      if ( step == 4 && ((score > 21 && score <= 49 && riskscore_agreed == 1) || score > 49) ) {
        this.step = step;
      }
    },
    isQuestionAnswer() {
      let score = (this.store.user.profile && this.store.user.profile.risk_score)?parseInt(this.store.user.profile.risk_score):0
      let qAnsEnabled = (this.store.user.profile && this.store.user.profile.qAnsEnabled)?parseInt(this.store.user.profile.qAnsEnabled):0
      let riskscore_agreed = (this.store.user.profile && this.store.user.profile.riskscore_agreed)?parseInt(this.store.user.profile.riskscore_agreed):0
      if ((score <= 49 && riskscore_agreed == 0) || ((score == 0 && qAnsEnabled == 0) || qAnsEnabled == 1)) {
        return false;
      } else {
        return true;
      }
    },
    createAccount() {
      let form = JSON.parse(JSON.stringify(this.form));
      if (form.account_type == "ISL" || form.account_type == "ZULU") {
        form.leverage = "0";
      }
      this.store
        .createAccount(form, true)
        .then(() => {
          // this.store.refreshUserData()
          if (this.isVerified()) {
            if (!this.hasDeposit()) {
              this.store.refreshUserData({},false,()=>{
                this.changeStep(3)
              })
            }
          } else {
            this.store.refreshUserData({},false,()=>{
              this.changeStep(2)
            })
          }
          try{
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event' : 'createaccount',
              'platformType' : form.platform,
              'accountType' : form.account_type,
              'registrationStep' : '1'
            });

            //  live account
            window.dataLayer.push({
              'event' : 'registration',
              'ctaType' : 'LiveAccount',
              'ctaPosition' : window.location.href //this should be replaced with an actual page path/cta button type
            });

          }catch (ex) {
            console.log('ex', ex)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startIpvNow() {
      let input_json = {};
      this.ipvStarted = !this.ipvStarted;
      this.store
        .getIPVToken(input_json)
        .then((response) => {
          this.intializeSumSub(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    intializeSumSub({ accesstoken, levelname }) {
      console.log(accesstoken,'sub',levelname)
      this.launchWebSdk(
        "https://api.sumsub.com",//"https://test-api.sumsub.com"
        levelname,
        accesstoken,
        this.store.user.email,
        "",
        ""
      );
    },

    launchWebSdk(
      apiUrl,
      flowName,
      accessToken,
      applicantEmail,
      applicantPhone,
      customI18nMessages
    ) {
      const _that = this;

      let snsWebSdkInstance = snsWebSdk
        .init(accessToken, () => this.store.sumSubToken.accesstoken)
        .withBaseUrl("https://api.sumsub.com")
        .withConf({
          lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
          email: applicantEmail,
          phone: applicantPhone,
          i18n: customI18nMessages, //JSON of custom SDK Translations
          uiConf: {
            customCssStr:
              "button.submit:active:not(:disabled), button.submit:hover:not(:disabled):not(.disabled):not(:active), button[type=submit]:active:not(:disabled), button[type=submit]:hover:not(:disabled):not(.disabled):not(:active), #loader .round-icon, .round-icon{ background: #073763; color: #fff; background-image:none;}button.submit, button[type=submit]{ background: #073763; color: #fff;} .step.active .title, h3, h4, .content .desktop p, .document-status h2{color:#414649} .step.active .bullet:before, .step.active.success .bullet:before, .radio-item .checkmark:after{background-color:#073763} .mobile-button .fa-icon, .accent{color:#073763} .checkbox input:checked~.checkmark{background-color:#073763 ;border-color:#073763} .step.active .line, .step.success .line{background-color:#073763} .document-status p, p, .checkbox, .radio-item{color:#888da8;}",
            // customCss: "https://url.com/styles.css"
            // URL to css file in case you need change it dynamically from the code
            // the similar setting at Customizations tab will rewrite customCss
            // you may also use to pass string with plain styles `customCssStr:`
          },
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        // see below what kind of messages WebSDK generates
        .on("idCheck.onApplicantSubmitted", (submitted) => {
          console.log("submitted", submitted);
          // console.log('stepCompleted', payload)
          // setTimeout(function () {
          //   _that.displayAvailableOption();
          // }, 15000);
        })
        .on("idCheck.onApplicantResubmitted", (payload) => {
          console.log("resubmitted", payload);
          // setTimeout(function () {
          //   _that.displayAvailableOption();
          // }, 15000);

          // if (messageType == "idCheck.applicantStatus") {
          if (
            "reviewStatus" in payload &&
            payload.reviewStatus == "completed"
          ) {
            // _that.skipDocumentUpload()

            if (
              "reviewResult" in payload &&
              "reviewRejectType" in payload.reviewResult &&
              payload.reviewResult.reviewRejectType == "RETRY"
            ) {
              setTimeout(function () {
                _that.iframeVar = true;
                _that.showFeaturesDiv = false;
                _that.asktoallowNotification = false;
              }, 15000);
            } else {
              _that.getSumSubResp();
            }
          } else if (
            "reviewStatus" in payload &&
            payload.reviewStatus == "pending"
          ) {
            // setTimeout(function () {
            //   _that.displayAvailableOption();
            // }, 15000);
          } else {
            console.log("sss");
          }
          // }
        })
        .on("idCheck.applicantStatus", (status) => {
          // console.log("status applicantStatus", status);
          // this.changeStep(3);
          if (
            status.reviewResult &&
            status.reviewResult.reviewAnswer == "GREEN"
          ) {
            this.sumsubVerified = true;
            // this.store.refreshUserData();

            /*window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event' : 'verifyaccount',
              'registrationStep' : '2',
            });*/
            this.store.refreshUserData({},false,()=>{
              this.changeStep(3)
            })
          }
          if (
            status.reviewResult &&
            status.reviewResult.reviewAnswer &&
            status.reviewResult.reviewAnswer == "RED" &&
            status.reviewResult.reviewRejectType &&
            status.reviewResult.reviewRejectType == "FINAL"
          ) {
            this.showRejectionBlock = true;
          }
          // if (
          //   "reviewStatus" in payload &&
          //   payload.reviewStatus == "completed"
          // ) {
          //   if (
          //     "reviewResult" in payload &&
          //     "reviewRejectType" in payload.reviewResult &&
          //     payload.reviewResult.reviewRejectType == "RETRY"
          //   ) {
          //     setTimeout(function () {
          //       _that.iframeVar = true;
          //       _that.showFeaturesDiv = false;
          //       _that.asktoallowNotification = false;
          //     }, 15000);
          //   } else {
          //     _that.getSumSubResp();
          //   }
          // } else if (
          //   "reviewStatus" in payload &&
          //   payload.reviewStatus == "pending"
          // ) {
          //   setTimeout(function () {
          //     _that.displayAvailableOption();
          //   }, 15000);
          // } else {
          //   console.log("sss");
          // }
        })
        .on("idCheck.onError", (error) => {
          console.log("onError", error);
        })
        .build();
      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch("#sumsub-websdk-container");
    },
  },
};
</script>
