/* eslint-disable */
import { defineStore } from 'pinia'
import * as API from '@/api/rx-factory'
import * as ENDPOINTS from '@/api/endpoints'
import router from '@/router'
import { useToast } from 'vue-toastification'
import ToastMessage from '@/components/shared/ToastMessage.vue'


const toast = useToast()

const successHandler = (message, redirect) => {
  toast(message, {
    timeout: 4000
  })
  if (redirect) {
    if (redirect && redirect == 'back') {
      router.back()
    } else {
      router.push(`/${redirect}`)
    }
  }
}



export const useMainStore = defineStore('main', {
  persist: {
    key: 'storekey',
    storage: window.localStorage,
    paths: ['user', 'depositsList', 'profile', 'countries', 'ticketReasons', 'allowedBonus', 'tradingAccounts', 'tradingAccountDetail', 'lastSelectedAccount', 'initialRequests'],
  },
  state: () => ({
    loading: false,
    showOTP: false,
    initialRequests: {
      accounts: false,
      deposits: false
    },
    verificationRequired: false,
    allowedBonus: 0,
    trans_id: null,
    trans_id_type: '',
    countries: [],
    user: {},
    sumSubToken: {},
    ticketReasons: {},
    tradingAccounts: {
      records: [],
      totalRecords: 0
    },
    profile: {},
    tradingAccountDetail: {
      "open_trades": {},
      "open_orders": {},
      "margin_detail": {},
      "history": {}
    },
    lastSelectedAccount: {},
    openTickets: {
      records: [],
      totalRecords: 0,
    },
    closedTickets: {
      records: [],
      totalRecords: 0,
    },
    ticketDetails: {},
    ticketid: '',
    ticketno: '',
    qrCode: null,
    depositsList: {
      records: [],
      totalRecords: 0
    },
    withdrawalsList: {
      records: [],
      totalRecords: 0
    },
    bonusList: {
      records: [],
      totalRecords: 0
    },
    tradingAccDetail: {
      "open_trades": {},
      "open_orders": {},
      "margin_detail": {},
      "history": {}
    },
    withdrawalAmount: null,
    withdrawalMethods: [],
    withdrawalCryptoMethods: []
  }),
  getters: {
    openTrades(state) {
      if (('open_trades' in state.tradingAccountDetail) && ('csv_array' in state.tradingAccountDetail.open_trades)) {
        const arr: Array<Object> = JSON.parse(JSON.stringify(state.tradingAccountDetail.open_trades['csv_array']))
        if (state.lastSelectedAccount['platform'] != 'MT5') {
          arr.shift()
        }
        return arr
      } else {
        []
      }
    },
    closedTrades(state) {
      if (('history' in state.tradingAccountDetail) && ('csv_array' in state.tradingAccountDetail.history)) {
        let arr: Array<Object> = JSON.parse(JSON.stringify(state.tradingAccountDetail.history['csv_array']))
        if (state.lastSelectedAccount['platform'] != 'MT5') {
          arr.shift()
          arr = arr.filter(i => i[12] <= 1)
        } else {
          arr = arr.filter(i => i[13] <= 1)
        }
        return arr
      } else {
        []
      }
    },
    liveAccounts(state) {
      return state.tradingAccounts.records.filter(i => parseInt(i.demo_live) == 1)
    },
    demoAccounts(state) {
      return state.tradingAccounts.records.filter(i => parseInt(i.demo_live) == 0)
    },
    liveSelectedAccount(state) {
      try {
        if (state.lastSelectedAccount['account_id']) {
          if (state.lastSelectedAccount['demo_live'] == "1") {
            return state.lastSelectedAccount;
          } else {
            return {};
          }
        } else {
          return {}
        }
      } catch (e) {
        return {}
      }
    }
  },
  actions: {

    //Login
    login(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_USER_LOGIN, params).then(response => {
        this.loading = false
        if (response.user.token) {
          try {
            (window as any).dataLayer.push({
              'event': 'login',
              'loginMethod': 'email'
            });
          } catch (ex) {
            console.log('ex', ex)
          }
          this.user = response.user
          router.replace('/dashboard')
        } else {
          this.verificationRequired = true
        }
      }).catch(error => {
        this.loading = false
      })
    },

    //Generate reset password link
    forgotPassword(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_USER_FORGOT_PASSWORD, params).then(response => {
        this.loading = false
        _that.success = true
        //successHandler('Reset link sent to your registered email. Please check', 'login')
      }).catch(error => {
        if (error.msg.code == 'REGISTER_ERROR') {
          _that.registerFirst = true
        }
        this.loading = false
      })
    },

    //Verify reset password link
    resetPassword(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_RESET_PASSWORD_FROM_HASH, params).then(response => {
        this.loading = false
        _that.success = true
        //successHandler('Success! Please login using your new password', 'login')
      }).catch(error => {
        this.loading = false
      })
    },

    //Verify login
    verifyLogin(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_USER_LOGIN_2FA, params).then(response => {
        try {
          (window as any).dataLayer.push({
            'event': 'login',
            'loginMethod': 'email'
          });
        } catch (ex) {
          console.log('ex', ex)
        }
        this.loading = false
        this.verificationRequired = false
        this.user = response.user
        router.replace('/dashboard')
      }).catch(error => {
        this.loading = false
      })
    },

    //Get user profile
    getUserProfile(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_GET_USER_PROFILE, params).then(response => {
        this.loading = false
        this.profile = response[0]
      }).catch(error => {
        this.loading = false
      })
    },

    //Change user password
    changePassword(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_CHANGE_PASSWORD, params).then(response => {
        this.loading = false
        _that.success = true
        _that.form.oldpassword = ''
        _that.form.password = ''
        _that.form.cpassword = ''
        //successHandler('Password changed', 'back')
      }).catch(error => {
        this.loading = false
      })
    },

    //Register
    register(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_REGISTRATION, params).then(response => {
        this.loading = false
        this.showOTP = true
        this.trans_id = response.insertId
      }).catch(error => {
        //console.log("error",error.msg.msg)
        try {
          (window as any).dataLayer.push({
            'event': 'error',
            'errorType': 'registration error', // or registration error
            'errorMessage': error.msg.msg //this should be replaced with an actual error message
          });
        } catch (ex) {
          console.log('ex', ex)
        }
        this.loading = false
      })
    },

    verifyRegistration(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_VERIFY_REGISTRATION, params).then(response => {
        this.loading = false
        this.trans_id = null
        this.user = response.user

        try {
          (window as any).gtag('event', 'conversion', {
            'send_to': 'AW-10955579630/3WakCKPwluYDEO7Bg-go'
          });
          (window as any).twq('event', 'tw-ocglw-octc9', {});
        } catch (ex) {
          console.log('ex', ex)
        }

        successHandler(`Registration successfull`, 'dashboard')
      }).catch(error => {
        //console.log('==>', error)
        try {
          (window as any).dataLayer.push({
            'event': 'error',
            'errorType': 'OTP error', // or registration error
            'errorMessage': error.msg.msg //this should be replaced with an actual error message
          });
        } catch (ex) {
          console.log('ex', ex)
        }
        this.loading = false
      })
    },

    //Get countries list
    getCountriesList(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      if (!this.countries.length) {
        API.get(ENDPOINTS.EP_GET_COUNTRIES_LIST).then(response => {
          this.loading = false
          this.countries = response
        }).catch(error => {
          this.loading = false
        })
      }
    },

    //Refresh user data and auth token
    refreshUserData(params = {}, loader = false,cb = ()=>{ return; }) {
      if (loader) {
        this.loading = true
      }
      this.resetOnLoadOrOperationCompletion()
      API.post(ENDPOINTS.EP_REFRESH_DATA, params).then(response => {
        this.loading = false
        this.user = response.user
        // router.replace('/dashboard')
        // if (response.user.verified_status == 1) {
        //   router.replace('/dashboard')
        // } else {
        //   router.replace('/verify')
        // }
        cb();
      }).catch(error => {
        this.loading = false
      })
    },

    //Get token for SumSub user IPV
    getIPVToken(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) {
          this.loading = true
        }
        API.post(ENDPOINTS.EP_GET_IDENFY_TOKEN, params).then(response => {
          this.loading = false
          this.sumSubToken = response
          resolve(response)
        }).catch(error => {
          reject('Token not generated')
          this.loading = false
        })
      })
    },

    //List of reasons for support ticket
    getTicketReasons(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_GET_TICKET_REASON_LIST, params).then(response => {
        this.loading = false
        this.ticketReasons = response
      }).catch(error => {
        this.loading = false
      })
    },

    //Get list of tickets
    getTicketsList(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_GET_TICKET_LIST, params).then(response => {
        this.loading = false
        if (params['status'] == '0') {
          this.openTickets = response
        } else {
          this.closedTickets = response
        }
      }).catch(error => {
        this.loading = false
      })
    },

    //Set last selected account
    setLastSelectedAccount(params = {}, dataKeys = { dataRequired: { margin_detail: true, open_trades: true, history: false, account_detail: false } }, inBackground, from) {
      this.lastSelectedAccount = Object.assign(this.lastSelectedAccount, params)
      if (from != 'internal-transfer') {
        this.getAccountDetails({ ...params, ...dataKeys }, !inBackground ? true : false)
      } else {
        this.getAccDetails({ ...params, ...dataKeys }, !inBackground ? true : false)
      }
    },

    //Get list of trading accounts
    getTradingAccounts(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_GET_TRADING_ACCOUNT_LIST, params).then(response => {
        this.loading = false
        this.tradingAccounts = response
        this.initialRequests.accounts = true
        if (response.records.length) {
          this.setLastSelectedAccount(response.records[0])
        }
      }).catch(error => {
        this.loading = false
      })
    },

    //Get detail of selected account
    getAccountDetails(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_GET_TRADING_ACCOUNT_DETAILS, params).then(response => {
        this.loading = false
        this.tradingAccountDetail = Object.assign(this.tradingAccountDetail, response)
      }).catch(error => {
        this.loading = false
      })
    },

    getAccDetails(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_GET_TRADING_ACCOUNT_DETAILS, params).then(response => {
        this.loading = false
        this.tradingAccDetail = Object.assign(this.tradingAccDetail, response)
      }).catch(error => {
        this.loading = false
      })
    },
    updateRiskscore(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) {
          this.loading = true
        }
        API.post(ENDPOINTS.EP_RISKSCORE_UPDATE, params).then(response => {
          this.loading = false;
          this.user.profile.riskscore_agreed = 1
          resolve(true);
        }).catch(error => {
          this.loading = false;
          reject(false);
        })
      })
    },
    submitQuestionsAnswer(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) {
          this.loading = true
        }
        API.post(ENDPOINTS.EP_QUESTION_STEP3, params).then(response => {
          this.loading = false;
          resolve(true);
        }).catch(error => {
          this.loading = false;
          reject(false);
        })
      })
    },
    //Create trading account
    createAccount(params = {}, loader = false) {
      if (!params['verificationSteps']) {
        const proceed = () => {
          if (loader) {
            this.loading = true
          }
          API.post(ENDPOINTS.EP_CREATE_ACCOUNT, params).then(response => {
            this.loading = false
            //this.getTradingAccounts({}, false)
            if (response.account && Object.keys(response.account).length > 0) {
              this.tradingAccounts.records.unshift(response.account)
              this.tradingAccounts.totalRecords = parseInt(this.tradingAccounts.totalRecords) + 1
              this.setLastSelectedAccount(this.tradingAccounts.records[0])
            }
            successHandler(`Account created succesfully`, 'manage-account')
          }).catch(error => {
            this.loading = false
          })
        }
        if (params['demo_live'] == '1') {
          (window as any).twq('event', 'tw-ocglw-octc8', {});
          if (this.user.verified_status) {
            proceed()
          } else {
            toast.warning(ToastMessage, {
              onClick: () => router.push('/verify'),
              timeout: false,
            })
          }
        } else {
          proceed()
        }
      } else {
        return new Promise((resolve, reject) => {
          if (loader) {
            this.loading = true
          }
          API.post(ENDPOINTS.EP_CREATE_ACCOUNT, params).then(response => {
            this.loading = false
            this.getTradingAccounts({}, false)
            resolve(true)
          }).catch(error => {
            this.loading = false
            reject(false)
          })

        })
      }
    },


    //Create callback request task
    updateLabel(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_UPDATE_LABEL, params).then(response => {
        this.loading = false
        if (response.account_data) {
          this.$patch((state) => {
            const ind = state.tradingAccounts.records.findIndex(i => i.account_id == response.account_data.account_id)
            if (ind > -1) {
              state.tradingAccounts.records.splice(ind, 1, response.account_data)
            }
          })
          this.lastSelectedAccount = Object.assign(this.lastSelectedAccount, response.account_data)
        }
        _that.success = true
        //successHandler('Account label updated', 'manage-account')
      }).catch(error => {
        this.loading = false
      })
    },
    //Account operations for Change Leverage, Internal Transfer and Change Password
    accountOperations(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      return new Promise((resolve, reject) => {
        API.post(ENDPOINTS.EP_ACCOUNT_PASSWORD_REQUEST_OTP, params).then(response => {
          this.loading = false
          this.trans_id = response.trans_id
          this.trans_id_type = params['operation']
          resolve(response)
        }).catch(error => {
          reject(error)
          this.loading = false
        })
      });
    },

    //Verify account operations OTP
    verifyAccountOperation(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      return new Promise((resolve, reject) => {
        let ep = ''
        if (params['type'] == 'CHANGE LEVERAGE') {
          ep = ENDPOINTS.EP_ACCOUNT_LEVERAGE_CHECK_OTP
        } else if (params['type'] == 'CHANGE PASSWORD') {
          ep = ENDPOINTS.EP_ACCOUNT_PASSWORD_CHECK_OTP
        } else if (params['type'] == 'INTERNAL TRANSFER') {
          ep = ENDPOINTS.EP_INTERNAL_TRANSFER_CHECK_OTP
        } else if (params['type'] == 'BONUS') {
          ep = ENDPOINTS.EP_BONUS_CHECK_OTP
        } else if (params['type'] == 'REMOVE_BONUS') {
          ep = ENDPOINTS.EP_REMOVE_BONUS
        } else if (params['type'] == 'WITHDARWAL') {
          ep = ENDPOINTS.EP_WITHDRAWAL_CHECK_OTP
        } else if (params['type'] == 'MASTER' || params['type'] == 'INVESTOR') {
          ep = ENDPOINTS.EP_ACCOUNT_PASSWORD_CHECK_OTP
        } else if (params['type'] == 'DISABLE ACCOUNT') {
          ep = ENDPOINTS.EP_DELETE_TRADING_ACCOUNT
        }
        API.post(ep, params).then(response => {
          this.loading = false
          this.trans_id = null
          if (response.account_data) {
            this.$patch((state) => {
              const ind = state.tradingAccounts.records.findIndex(i => i.account_id == response.account_data.account_id)
              if (ind > -1) {
                state.tradingAccounts.records.splice(ind, 1, response.account_data)
              }
            })
            this.lastSelectedAccount = Object.assign(this.lastSelectedAccount, response.account_data)
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error.msg.msg)
        })
      })
    },

    //Account operations resend email
    resendOperationsLink(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_RESEND_EMAIL_LINK_ACCOUNTS, params).then(response => {
        this.loading = false
        this.trans_id = null
        router.replace('/dashboard')
      }).catch(error => {
        this.loading = false
      })
    },

    //Create support ticket
    createSupportTicket(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_TICKET_REQUEST_OTP, params).then(response => {
        this.loading = false
        this.showOTP = true
        this.trans_id = response.trans_id
      }).catch(error => {
        this.loading = false
      })
    },

    //Create payment method crypto
    addcryptopaymentmethod(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_ADD_CRYPTO_Methods, params).then(response => {
        this.loading = false
        this.showOTP = true
        this.trans_id = response.trans_id
      }).catch(error => {
        this.loading = false
      })
    },

    //Verify support ticket OTP
    confirmSupportTicket(params = {}, loader = false, _that) {
      const data = JSON.parse(JSON.stringify(params));
      delete params['from']
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_TICKET_CHECK_OTP, params).then(response => {
        this.resetOnLoadOrOperationCompletion()
        _that.success = true;
        if (data['from'] && data['from'] == 'add_payment') {
          _that.reset()
        } else {
          _that.form.reason = '';
          _that.form.subject = '';
          _that.form.account_id = '';
          _that.form.message = '';
          _that.attachment = {};
          _that.otp = ''
        }
        // successHandler(`Ticket created`, 'open-tickets')
      }).catch(error => {
        this.loading = false
      })
    },

    //Get ticket details
    getTicketDetails(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_GET_TICKET_DETAIL, params).then(response => {
        this.loading = false
        this.ticketDetails = response
        this.ticketid = response.ticket[0].id
        this.ticketno = response.ticket[0].ticketnumber
        if (response.ticket[0].reason == '97') {
          _that.ticketDetail(response.ticket[0].id, response.ticket[0].ticketnumber)
        }
      }).catch(error => {
        this.loading = false
      })
    },

    //Create ticket thread
    createThread(params: FormData, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_TICKET_REPLY, params).then(response => {
        this.loading = false
        this.getTicketDetails({ ticket_id: params.get('ticket_id') }, true)
      }).catch(error => {
        this.loading = false
      })
    },

    //Get available bonus
    getAvailableBonus(params: FormData, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_TRADING_BONUS, params).then(response => {
        this.loading = false
        this.allowedBonus = response.allowedBonus
      }).catch(error => {
        this.loading = false
      })
    },

    //Get 2FA QR code
    generate2FA(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_GENERATE_2FA_QRCODE, params).then(response => {
        this.loading = false
        this.qrCode = response
      }).catch(error => {
        this.loading = false
      })
    },

    //Verify 2FA 
    verify2FA(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_VERIFY_2FA_QRCODE, params).then(response => {
        this.loading = false
        this.refreshUserData()
        if (params['redirect']) {
          if (params['redirect'] == '/manage-funds') {
            router.push({ path: params['redirect'], 'query': { from: 'google-auth' } })
          } else {
            router.push(params['redirect'])
          }
        } else {
          _that.enabled2fa = true;
        }
      }).catch(error => {
        this.loading = false
      })
    },

    //Verify 2FA 
    disable2FA(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_DISABLE_2FA_QRCODE, params).then(response => {
        _that.submitted = false
        this.loading = false
        this.refreshUserData()
      }).catch(error => {
        this.loading = false
      })
    },

    //Generate transaction ID and deposit url for Visa, Mastercards, Skrill and Neteller
    generateDepositURL(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) {
          this.loading = true
        }
        let ep;
        if (params['method_id'] && params['method_id'] == '17') {
          ep = ENDPOINTS.EP_GET_PRAXIS_URL
        } else if (params['method_id'] && params['method_id'] == '18') {
          ep = ENDPOINTS.EP_CRYPTO_DEPOSIT
        } else {
          ep = ENDPOINTS.EP_CREDIT_CARD_DEPOSIT
        }

        API.post(ep, params).then(response => {
          this.loading = false
          if (params['method_id'] && params['method_id'] == '2') {
            resolve(response)
          } else if (params['method_id'] && params['method_id'] == '18') {
            resolve(response)
          } else {
            if (params['method_id'] && params['method_id'] == 17) {
              window.open(response.redirect_url, '_blank').focus();
            } else {
              window.open(response.url, '_blank').focus();
            }
          }
        }).catch(error => {
          this.loading = false
          reject(null)
        })
      })
    },
    startDemoFunding(params = {}, loader = false, _that) {
      return new Promise((resolve, reject) => {
        if (loader) {
          this.loading = true
        }
        API.post(ENDPOINTS.EP_DEMO_DEPOSIT, params).then(response => {
          this.loading = false
          if (params['comment'] == 'deposit') {
            _that.AddepsoitFund = false
            _that.success = true
          } else if (params['comment'] == 'withdraw') {
            _that.$parent.withdrawsuccess = true
          }
          resolve(response)
          //_that.deposit_amount = response.newbalance
          //successHandler('Deposit successfully', '')
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },


    createWithdrawalRequest(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_WITHDRAWAL_REQUEST_OTP, params).then(response => {
        this.loading = false
        this.trans_id = response.trans_id
        this.trans_id_type = 'withdrawal'

      }).catch(error => {
        this.loading = false
      })
    },

    //Generate transaction ID and deposit url for Visa, Mastercards, Skrill and Neteller
    getTransactionsList(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      let ep;
      if (params['type'] == 'DEPOSITS') {
        ep = ENDPOINTS.EP_GET_DEPOSIT_LIST
      } else if (params['type'] == 'WITHDRAWALS') {
        ep = ENDPOINTS.EP_GET_WITHDRAWAL_LIST
      } else if (params['type'] == 'BONUS') {
        ep = ENDPOINTS.EP_GET_BONUS_LIST
      }

      API.post(ep, params).then(response => {
        this.loading = false
        if (params['type'] == 'DEPOSITS') {
          this.initialRequests.deposits = true
          this.depositsList = response
        } else if (params['type'] == 'WITHDRAWALS') {
          this.withdrawalsList = response
        } else if (params['type'] == 'BONUS') {
          this.bonusList = response
        }
      }).catch(error => {
        this.loading = false
      })
    },

    //Create callback request task
    requestCallback(params = {}, loader = false, _that) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_REQUEST_CALLBACK, params).then(response => {
        this.loading = false
        this.trans_id = null
        _that.success = true
        _that.form.name = ''
        _that.form.timezone = ''
        _that.form.date = null
        _that.form.mobile = ''
        _that.form.message = ''
        _that.form.account_id = ''
        // successHandler('Our team has been notified of your request and will be in touch with you soon.', 'dashboard')
      }).catch(error => {
        this.loading = false
      })
    },

    callWithdrawalMethodList(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_GET_WITHDRAWAL_METHOD_LIST).then(response => {
        this.loading = false
        this.withdrawalMethods = response
      }).catch(error => {
        this.loading = false
      })
    },

    addCryptoMethod(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_ADD_CRYPTO_Methods, params).then(response => {
        this.loading = false
        this.withdrawalCryptoMethods = response
      }).catch(error => {
        this.loading = false
      })
    },

    //Reset state vars after OTP verification
    resetOnLoadOrOperationCompletion() {
      this.loading = false
      this.showOTP = false
    },

    //Logout and reset store
    logout() {
      this.loading = true
      setTimeout(() => {
        this.$reset()
        router.replace('/login')
        this.loading = false
      }, 1500)
    },
    getConversionTagCount(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_GET_CONVERSION_TAG_COUNT, params).then(response => {
        if (response.length && response[0].count > 0) {
          API.post(ENDPOINTS.EP_GET_CONVERSION_TAG_COUNT, { deposit_count_flag: 1 }).then(response => {
          }).then(r => {
            try {
              (window as any).gtag('event', 'conversion', {
                'send_to': 'AW-10955579630/7DFkCNXTqN0DEO7Bg-go',
              });
              (window as any).twq('event', 'tw-ocglw-oclu1', {
              });
            } catch (ex) {
              console.log('ex', ex)
            }
          }).catch(e => {
          })
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    updateConversionTagCount(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_UPDATE_CONVERSION_TAG_COUNT).then(response => {
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    cancelticket(params = {}, loader = false) {
      if (loader) {
        this.loading = true
      }
      API.post(ENDPOINTS.EP_CLOSE_SUPPORT_TICKET, params).then(response => {
        this.loading = false
        router.push('/closed-tickets')
      }).catch(error => {
        this.loading = false
      })
    },
    ticketdetaillist(data, _that) {
      return new Promise((resolve, reject) => {
        API.post(ENDPOINTS.EP_TICKET_TRANS_DETAIL, data).then(response => {
          //console.log("resp============>",response)
          if (response.length > 0) {
            _that.reqforward = true
          }
          this.loading = false
          resolve(response)
        }).catch(error => {
          this.loading = false
        })
      })
    },
  },
})