<template>
  <form
    action="https://pay.skrill.com"
    method="post"
    target="_blank"
    id="skrillForm"
  >
    <input
      type="hidden"
      name="pay_to_email"
      :value="static_vars.skrill.pay_to_email"
    />
    <input type="hidden" name="pay_from_email" :value="store.user.email" />
    <input
      type="hidden"
      name="status_url"
      :value="static_vars.skrill.status_url"
    />
    <input type="hidden" name="return_url" :value="`${url}/deposit`" />
    <input
      type="hidden"
      name="firstname"
      :value="store.user.profile ? store.user.profile.firstname : ''"
    />
    <input
      type="hidden"
      name="lastname"
      :value="store.user.profile ? store.user.profile.lastname : ''"
    />

    <input
      type="hidden"
      name="merchant_id"
      :value="static_vars.skrill.merchant_id"
    />

    <input type="hidden" name="language" value="EN" />
    <input type="hidden" name="amount" :value="amount" />
    <input type="hidden" name="currency" :value="currency_selected" />
    <input type="hidden" name="payment_methods" :value="2" />
    <input type="hidden" name="email" :value="store.user.email" />
    <input
      type="hidden"
      name="merchant_fields"
      :value="static_vars.skrill.merchant_fields"
    />
    <input type="hidden" name="tradingAccount" :value="account_id" />
    <input
      type="hidden"
      name="return_url_target"
      :value="static_vars.skrill.return_url_target"
    />
    <input
      type="hidden"
      name="cancel_url_target"
      :value="static_vars.skrill.cancel_url_target"
    />
    <input type="hidden" name="transaction_id" :value="transaction_id" />
    <input
      type="hidden"
      name="detail1_description"
      :value="'Deposit in ' + account_id"
    />
    <input type="hidden" name="detail1_text" value="Payment for account id" />
  </form>
</template>
<script>
import { useMainStore } from "@/store";
import { GLOBAL_BASE_URL } from "@/api/endpoints";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  props: ["amount", "currency_selected", "account_id", "transaction_id"],
  data() {
    return {
      url: GLOBAL_BASE_URL,
    };
  },
};
</script>
