<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}
</style>
<template>
  <div v-if="$route.name == 'Web Trader' || width > 1345">
    <div class="sidebar box-shadow">
      <!-- <router-link to="" href="#" class="close-btn"> <i class="fas fa-times"></i></router-link> -->
      <ul>
        <li :class="$route.path == '/dashboard' ? 'active' : ''">
          <router-link to="/dashboard">
            <div class="icon-holder">
              <img src="assets/images/dash-ico1.png" alt="" /><img
                src="assets/images/dash-ico1a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Dashboard</span>
          </router-link>
        </li>
        <li :class="$route.path == '/manage-account' ? 'active' : ''">
          <router-link to="/manage-account">
            <div class="icon-holder">
              <img src="assets/images/dash-ico2.png" alt="" /><img
                src="assets/images/dash-ico2a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Manage Accounts</span>
          </router-link>
        </li>
        <li :class="$route.path == '/manage-funds' ? 'active' : ''">
          <router-link to="/manage-funds">
            <div class="icon-holder">
              <img src="assets/images/dash-ico3.png" alt="" /><img
                src="assets/images/dash-ico3a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Fund</span>
          </router-link>
        </li>
        <!-- <li :class="$route.path == '/bonus' ? 'active' : ''">
          <router-link to="/bonus">
            <div class="icon-holder">
              <img src="assets/images/dash-ico4.png" alt="" /><img
                src="assets/images/dash-ico4a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Bonus</span>
          </router-link>
        </li> -->
        <!-- <li :class="$route.path == '/partner' ? 'active' : ''">
          <router-link to="/partner">
            <div class="icon-holder">
              <img src="assets/images/dash-ico5.png" alt="" /><img
                src="assets/images/dash-ico5a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Become a Partner</span>
          </router-link>
        </li> -->
        <li :class="$route.path == '/tools' ? 'active' : ''">
          <router-link to="/tools">
            <div class="icon-holder">
              <img src="assets/images/dash-ico6.png" alt="" /><img
                src="assets/images/dash-ico6a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Tools</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <transition v-else name="slide">
    <div v-if="sidebarVisible" class="sidebar box-shadow">
      <!-- <router-link to="" href="#" class="close-btn"> <i class="fas fa-times"></i></router-link> -->
      <ul>
        <li :class="$route.path == '/dashboard' ? 'active' : ''">
          <router-link
            @click="$parent.sidebarVisible = !$parent.sidebarVisible"
            to="/dashboard"
          >
            <div class="icon-holder">
              <img src="assets/images/dash-ico1.png" alt="" /><img
                src="assets/images/dash-ico1a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Dashboard</span>
          </router-link>
        </li>
        <li :class="$route.path == '/manage-account' ? 'active' : ''">
          <router-link
            @click="$parent.sidebarVisible = !$parent.sidebarVisible"
            to="/manage-account"
          >
            <div class="icon-holder">
              <img src="assets/images/dash-ico2.png" alt="" /><img
                src="assets/images/dash-ico2a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Manage Accounts</span>
          </router-link>
        </li>
        <li :class="$route.path == '/manage-funds' ? 'active' : ''">
          <router-link
            @click="$parent.sidebarVisible = !$parent.sidebarVisible"
            to="/manage-funds"
          >
            <div class="icon-holder">
              <img src="assets/images/dash-ico3.png" alt="" /><img
                src="assets/images/dash-ico3a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Fund</span>
          </router-link>
        </li>
        <li :class="$route.path == '/bonus' ? 'active' : ''">
          <router-link
            @click="$parent.sidebarVisible = !$parent.sidebarVisible"
            to="/bonus"
          >
            <div class="icon-holder">
              <img src="assets/images/dash-ico4.png" alt="" /><img
                src="assets/images/dash-ico4a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Bonus & Promotions</span>
          </router-link>
        </li>
        <li :class="$route.path == '/partner' ? 'active' : ''">
          <router-link
            @click="$parent.sidebarVisible = !$parent.sidebarVisible"
            to="/partner"
          >
            <div class="icon-holder">
              <img src="assets/images/dash-ico5.png" alt="" /><img
                src="assets/images/dash-ico5a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Become a Partner</span>
          </router-link>
        </li>
        <li :class="$route.path == '/tools' ? 'active' : ''">
          <router-link
            @click="$parent.sidebarVisible = !$parent.sidebarVisible"
            to="/tools"
          >
            <div class="icon-holder">
              <img src="assets/images/dash-ico6.png" alt="" /><img
                src="assets/images/dash-ico6a.png"
                alt=""
                class="hover"
              />
            </div>
            <span>Tools</span>
          </router-link>
        </li>
      </ul>
    </div>
  </transition>
</template>
<script>
export default {
  props: ["sidebarVisible"],
  data() {
    return {
      width: 0,
    };
  },
  mounted() {
    this.width = window.innerWidth;
  },
};
</script>
