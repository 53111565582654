<template>
  <div class="main-content f-left">
    <div class="form-holder" v-if="totalScore() == 0">
      <Form @submit="submit">
        <div v-for="(questions,ques_key) in static_vars.questionList" :key="ques_key">
          <p v-if="ques_key == Question_number">Question {{ques_key}}:- {{questions.question}}</p>
          <ul class="listQuestios" v-if="ques_key == Question_number">
            <li v-for="(item,answer_num) in questions.answers_list" :key="answer_num">
              <label class="customRadioBox" :class="(answer_num == answer_list[ques_key])?'active':''">{{item.answer}}
                <Field type="radio" :name="'Question '+ques_key+' Answers'" :value="answer_num" v-model="answer_list[ques_key]" rules="required"/>
                <span class="checkmark"></span>
              </label>
            </li>
          </ul>
          <ErrorMessage :name="'Question '+ques_key+' Answers'" v-if="ques_key == Question_number"/>
        </div>
        <div class="btn-holder">
          <a href="javascript:void(0)" class="btn btn-yellow" @click="previous" v-if="Question_number != '1'">
            <span>Previous</span>
          </a>
          <button type="submit" class="btn btn-blue">
            <span v-if="Object.keys(static_vars.questionList).length  == parseInt(Question_number)">Submit</span>
            <span v-else>Next</span>
          </button>
        </div>
      </Form>
        <!-- {{Object.keys(static_vars.questionList).length  == parseInt(Question_number)}}---{{Object.keys(static_vars.questionList).length}}-{{parseInt(Question_number)}}
        <ol v-for="(ans,question) in answer_list" :key="question">
          <li>
            {{question}} : {{static_vars.questionList[question].question}} - Score :
            <span v-if="ans">{{static_vars.questionList[question].answers_list[ans].score}}</span>
            <span v-else>N/A</span>
          </li>
        </ol> -->
    </div>
    <div v-else-if="totalScore() <= 21">
      <p>Based on the answers you provided in our suitability questionnaire, we do not consider our products to be appropriate for you</p>
      <p>We recommend that you create a demo account enable you practice using AAAFX's trading platform  in a risk-free environment</p>
      <!-- <button type="submit" class="btn btn-blue disabled" disabled>
        <span>Next</span>
      </button> -->
    </div>
    <div v-else-if="totalScore() <= 49">
      <p>Forex and CFDs are complex investment products that come with a high risk of losing money rapidly By continuing you confirm that you understand how CF. and FOIE% work and that you can afford to take the high risk of losing your money</p>
      <label class="customCheckbox d-inline-flex m-0">
          I Agree
          <input type="checkbox" v-model="iAgree"/><span class="checkmark"></span>
      </label>
      <button type="submit" class="btn btn-blue" @click="nextStep()" :class="[{'disable':iAgree}]">
        <span>Next</span>
      </button>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  setup() {
    return { store : useMainStore() };
  },
  /*mounted() {
    this.store.getCountriesList();
  },*/
  data() {
    return {
      answer_list : {},
      Question_number : '1',
      iAgree : false
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    nextStep(){
      if(this.iAgree){
        /*this.store.$patch(state => {
          state.user.profile.riskscore_agreed = 1
        })*/
        this.store
        .updateRiskscore({}, true)
        .then(() => {
          this.$parent.changeStep(4);
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    submit() {
      let key = (parseInt(this.Question_number) + 1).toString();
      if(key in this.answer_list){
        this.Question_number = key
      }else{
        this.getScore()
        // alert('submit')
      }
    },
    previous() {
      let key = (parseInt(this.Question_number) - 1).toString();
      if(key in this.answer_list){
        this.Question_number = key;
      }
    },
    totalScore() {
      let score = (this.store.user.profile && this.store.user.profile.risk_score)?parseInt(this.store.user.profile.risk_score):0
      return score
    },
    getScore(){
      // let score = 0
      let answer_json = []
      for(const question in this.answer_list){
        answer_json.push({
          "question_id": question,
          "ans_id": this.static_vars.questionList[question].answers_list[this.answer_list[question]].id,
          "answer": this.static_vars.questionList[question].answers_list[this.answer_list[question]].answer,
          "points": this.static_vars.questionList[question].answers_list[this.answer_list[question]].score,
          "type": this.static_vars.questionList[question].type
        })
        // score += this.static_vars.questionList[question].answers_list[this.answer_list[question]].score || 0
      }
      let requestData = {
        ques_ans_array : answer_json
      }
          // console.log('score :-',score)
      // console.log(requestData)

      this.store.submitQuestionsAnswer(requestData, true).then(() => {
        console.log(this.$parent)
        this.store.refreshUserData({},false,()=>{
          this.$parent.changeStep(4);
        })
        // console.log('submited')
      }).catch((error) => {
        console.log(error);
      });
    },
    set_answer_list(){
      Object.keys(this.static_vars.questionList).forEach(question=>{
        this.answer_list[question] = ""
      })
    }
  },
  created(){
    this.set_answer_list()
  }
};
</script>
