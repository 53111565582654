<template>
  <div class="footerSection page-footer">
    <div class="bottom-footer">
      <div class="wrapper disclaimer">
          <div class="d-flex mb-2 justify-content-center">
            <router-link class="flex-none mr-3" to="/dashboard">
                <img src="assets/images/logo-footer.webp"  width="206" alt="Logo Footer" title="Footer Logo">
            </router-link>
          </div>
          <div class="d-flex">
            <p>Legal Information: </p>
          </div>
          <div class="d-flex mb-2">
              <span><img src="assets/images/footer-flag2.webp"  width="40" alt="Logo Footer" title="Footer Logo"></span><p>Triple A Experts Investment Services Single Member SA is EU authorized and regulated by the Hellenic Capital Market Commission (id:2/540/17.2.2010) with registered address at Triple A Experts Investment Services S.A., 14 Akti Kondyli street, 18545 Piraeus, Greece.</p>        
          </div>
          <div class="d-flex mb-2">
              <span><img src="assets/images/footer-flag3.webp"  width="25" alt="Logo Footer" title="Footer Logo"></span><p>Sikhula Venture Capital (Pty) Ltd. is authorized and regulated by the South African Financial Sector Conduct Authority (FSCA No. 2017/315029/07).</p>        
          </div>
          <div class="d-flex mb-2">
            <p>www.aaafx.com is operated by Triple A Experts Investment Services Single Member SA.</p>
          </div>
          <div class="d-flex mb-2">
            <p><strong>Risk Disclosure:</strong> 1CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. 78% of retail investor accounts lose money when trading CFDs with AAAFx. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.</p>
          </div>
      </div>
    </div>
  </div>
</template>
