<template>
  <AppLoader
    v-if="
      ($route.name == 'Login' ||
        $route.name == 'Register' ||
        $route.name == 'Forgot Password' ||
        $route.name == 'Reset Password') &&
      store.loading
    "
  />
  <router-view />
</template>

<script>
import AppLoader from "./components/shared/AppLoader.vue";
import { useMainStore } from "@/store";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  components: { AppLoader },
};
</script>
