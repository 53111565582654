<template>
    <div class="login-page register-page reset-pass">
        <div id="main-wrapper" class="w-100">
            <div v-if="success">
              <div class="mfp-bg animated zoomIn mfp-ready"></div>
              <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor animated zoomIn mfp-ready" tabindex="-1" style="overflow: hidden auto;">
                <div class="mfp-container mfp-s-ready mfp-inline-holder">
                  <div class="mfp-content">
                    <div id="upload-confirm" class="white-popup-block popup-content popup-small">
                      <div class="content t-white t-center">
                         <p>A confirmation link has been sent to your registered email ID. Kindly click on the link to reset your login password.</p>
                      </div>
                      <div class="btn-holder t-center">
                        <a href="#" class="btn btn-lblue close-popup w-auto" @click.prevent="$router.push('/login')">OK </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="registerFirst">
              <div class="mfp-bg animated zoomIn mfp-ready"></div>
              <div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor animated zoomIn mfp-ready" tabindex="-1" style="overflow: hidden auto;">
                <div class="mfp-container mfp-s-ready mfp-inline-holder">
                  <div class="mfp-content">
                    <div id="upload-confirm" class="white-popup-block popup-content popup-small">
                      <div class="content t-white t-center">
                         <p>This email address is not registered with us. Click <a href="#" @click.prevent="registerFirst = false;$router.push('/register')">here</a> to create an account now</p>
                      </div>
                      <div class="btn-holder t-center">
                        <a href="#" class="btn btn-lblue close-popup w-auto" @click.prevent="registerFirst = false">OK </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="login-page">
                <div class="register-holder center-div wow fadeIn">
                     <img width="145" src="assets/images/logo.svg" alt="" />
                    <br />
                    <br />
                    <div class="form-holder">
                        <h3 class="t-center">Forgot Password</h3>
                        <Form @submit="submit">
                            <ul>
                                <li>
                                    <label for="">Email Address</label>
                                    <Field class="form-control" name="Email address" rules="required|email" v-model="form.email" />
                                    <ErrorMessage name="Email address" />
                                </li>
                            </ul>
                            <div class="btn-holder">
                                <button class="btn btn-yellow">
                                    <span>Submit</span>
                                </button>
                            </div>
                        </Form>
                    </div>

                    <div class="links clearfix">
                        <div class="f-left t-left">
                            <p>
                                Already have an account? <br />
                                <router-link to="/login">LOGIN</router-link>
                            </p>
                        </div>
                        <div class="f-right t-right">
                            <p>
                                Don't have an account? <br />
                                <router-link to="/register">CREATE AN ACCOUNT NOW!</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMainStore } from "@/store";
    import { Form,Field, ErrorMessage } from "vee-validate";
    export default {
        setup() {
            const store = useMainStore();
            return { store };
        },
        mounted() {
            this.store.getCountriesList();
        },
        data() {
            return {
                form: {
                    email: "",
                },
                success : false,
                registerFirst : false
            };
        },
        components: {
            Form,
            Field,
            ErrorMessage,
        },
        methods: {
            submit() {
                this.store.forgotPassword(this.form, true,this);
            },
        },
    };
</script>
