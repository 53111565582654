<template>
  <div class="table-responsive">
    <table
      class="table customize opencloseTrade-table"
      v-if="
        store.lastSelectedAccount.platform &&
        (store.lastSelectedAccount.platform == 'MT4' ||
          store.lastSelectedAccount.platform == 'ZULU')
      "
    >
      <thead>
        <tr class="position-relative">
          <th>Order#</th>
          <th>Symbol</th>
          <th>Type</th>
          <th>Volume</th>
          <th>Open Price</th>
          <th>PNL</th>
        </tr>
      </thead>
      <tbody v-if="store.openTrades && store.openTrades.length">
        <tr v-for="list in store.openTrades" :key="list[0]">
          <td>{{ list[0] }}</td>
          <td>
            {{ list[2] }}
          </td>
          <td>
            {{ list[3] }}
          </td>
          <td>{{ list[4] / 100 }}</td>
          <td>{{ list[6] }}</td>
          <td>
            {{
              COMMON.priceWithCurrency(
                store.lastSelectedAccount,
                COMMON.convertToTwoDecimalDigits(list[9])
              )
            }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="selectHere">
          <td class="noRecordFound t-center" colspan="6">
            <p>No record found</p>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      class="table customize opencloseTrade-table"
      v-else-if="
        store.lastSelectedAccount.platform &&
        store.lastSelectedAccount.platform == 'MT5'
      "
    >
      <thead>
        <tr class="position-relative">
          <th>Order#</th>
          <th>Symbol</th>
          <th>Type</th>
          <th>Volume</th>
          <th>Open Price</th>
          <th>PNL</th>
        </tr>
      </thead>
      <tbody v-if="store.openTrades && store.openTrades.length">
        <tr v-for="list in store.openTrades" :key="list[0]">
          <td>{{ list[0] }}</td>
          <td>{{ list[2] }}</td>
          <td>{{ list[3] == 1 ? "Buy" : "Sell" }}</td>
          <td>{{ list[4] }}</td>
          <td>{{ list[6] }}</td>
          <td>
            {{
              COMMON.priceWithCurrency(
                store.lastSelectedAccount,
                COMMON.convertToTwoDecimalDigits(list[9])
              )
            }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="selectHere">
          <td class="noRecordFound t-center" colspan="6">
            <p>No record found</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import moment from "moment";

export default {
  name: "Home",
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {};
  },
  methods: {
    selectTradingAccount(e) {
      this.store.setLastSelectedAccount(e);
    },
    timeFunc(getData) {
      return moment.unix(getData).format("Do MMM, YYYY HH:mm:ss");
    },
  },
};
</script>
