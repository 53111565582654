<template>
  <div class="login-page">
    <div id="">
      <div class="login-page">
        <div class="login-holder center-div wow fadeIn">
          <img width="145" src="assets/images/logo.svg" alt="" />
          <h1>Client Login</h1>

          <div class="form-holder">
            <Form v-if="!store.verificationRequired" @submit="submit">
              <ul>
                <li>
                  <label for="">Email Address</label>
                  <Field
                    class="form-control"
                    name="Email address"
                    rules="required|email"
                    v-model="form.email"
                  />
                  <ErrorMessage name="Email address" class="errorMsg" />
                </li>
                <li class="full-width f-left">
                  <label for="">Password</label>
                  <div style="position: relative">
                    <Field
                      :type="inputtype"
                      class="form-control"
                      name="password"
                      v-model="form.password"
                      rules="required|min:6"
                    />
                   <vue-feather :type="iconType" class="toggle-password" @click.prevent="switchVisibility"></vue-feather>
                  </div>
                  <ErrorMessage name="password" class="errorMsg" />
                </li>
                <li class="clearfix">
                  <div class="checkbox checkbox2 f-left">
                    <input type="checkbox" name="" value="" />
                    <span></span>
                    <p>Remember me</p>
                  </div>
                  <router-link to="/forgot-password" class="f-right"
                    >Forgot Password</router-link
                  >
                </li>
              </ul>
              <div class="btn-holder">
                <button type="submit" class="btn btn-yellow">
                  <span>Login</span>
                </button>
              </div>
            </Form>
            <Form v-else @submit="verify">
              <h4 style="color: #fff">Two-step Verification</h4>
              <p style="color: #fff">
                Please enter the one time passcode shown in the Google
                Authenticator app.
              </p>
              <ul>
                <li>
                  <label for="">Google Auth Code</label>
                  <Field
                    autocomplete="off"
                    class="form-control"
                    name="form.google_token"
                    rules="required|min:6|max:6|numeric"
                    v-model="form.google_token"
                  />
                  <ErrorMessage name="form.google_token" style="color:white" />
                </li>
              </ul>
              <div class="btn-holder">
                <button type="submit" class="btn btn-yellow">
                  <span>Submit</span>
                </button>
              </div>
            </Form>
          </div>
          <div class="links">
            <p>Don’t have an account?</p>
            <router-link to="/register">Create an account Now!</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  mounted() {
    this.store.getCountriesList();
  },
  data() {
    return {
        form: {
            email: "",
            password: "",
            google_token: "",
        },
        showpassword: false,
        iconType : 'eye-off',
        inputtype : 'password',
                
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    submit() {
      this.store.login(this.form, true);
    },
    switchVisibility() {
        this.showpassword = !this.showpassword
        if(this.showpassword){
            this.inputtype = 'text'
            this.iconType = 'eye'
        }else{
            this.inputtype = 'password'
            this.iconType = 'eye-off'
        } 
    },
    verify() {
      this.store.verifyLogin(this.form, true);
    },
  },
};
</script>
