<style scoped>
.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 157px);
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<template>
  <div id="main-wrapper">
    <div class="content-container dashboard-page">
      <div class="page-wrap">
        <div
          v-if="
            store.initialRequests.accounts && store.initialRequests.deposits
          "
        >
          <div v-show="showSteps()" class="page-content clearfix">
            <Verify />
          </div>
          <div v-show="!showSteps()" class="page-content clearfix">
            <div class="main-content f-left">
              <div class="row-sec clearfix">
                <div class="trading-id first-sec themeSelect">
                  <div class="holder">
                   <!--  <AccountsDropdown /> -->
                   <select class="form-control" name="account" v-model="store.lastSelectedAccount.account_id"  @change="GetTradingAccountDetail">
                        <option value="" label="Select Trading ID">Select Trading ID</option>
                        <optgroup label="Demo MT4">
                          <option :value="list.account_id" v-for="list,index in MergeAcc('0','MT4')" :key ="index" >{{list.account_id}}</option>
                        </optgroup>
                        <optgroup label="Demo MT5">
                          <option :value="list.account_id" v-for="list,index in MergeAcc('0','MT5')" :key ="index" >{{list.account_id}}</option>
                        </optgroup>
                        <optgroup label="Live MT4">
                          <option :value="list.account_id" v-for="list,index in MergeAcc('1','MT4')" :key ="index" >{{list.account_id}}</option>
                        </optgroup>
                        <optgroup label="Live MT5">
                          <option :value="list.account_id" v-for="list,index in MergeAcc('1','MT5')" :key ="index" >{{list.account_id}}</option>
                        </optgroup>
                        <optgroup label="Live ZTP">
                          <option :value="list.account_id" v-for="list,index in MergeAcc('1','ZTP')" :key ="index" >{{list.account_id}}</option>
                        </optgroup>
                    </select>
                  </div>
                </div>
                <div class="balances-sec first-sec">
                  <div class="listing holder">
                    <div class="balance-holder">
                      <p>Leverage</p>
                      <p>
                        <span>{{
                          store.lastSelectedAccount.leverage
                            ? `${store.lastSelectedAccount.leverage}X`
                            : "N/A"
                        }}</span>
                      </p>
                    </div>
                    <div class="balance-holder">
                      <p>Balance</p>
                      <p>
                        <span>
                          {{
                            COMMON.priceWithCurrency(
                              store.lastSelectedAccount,
                              store.tradingAccountDetail.margin_detail.balance
                            )
                          }}
                        </span>
                      </p>
                    </div>
                    <div class="balance-holder">
                      <p>Equity</p>
                      <p>
                        <span>
                          {{
                            COMMON.priceWithCurrency(
                              store.lastSelectedAccount,
                              store.tradingAccountDetail.margin_detail.equity
                            )
                          }}
                        </span>
                      </p>
                    </div>
                    <div class="balance-holder">
                      <p>Free Margin</p>
                      <p>
                        <span>
                          {{
                            COMMON.priceWithCurrency(
                              store.lastSelectedAccount,
                              store.tradingAccountDetail.margin_detail
                                .freeMargin
                            )
                          }}
                        </span>
                      </p>
                    </div>
                    <div class="balance-holder">
                      <p>PNL</p>
                      <p>
                        <span>
                          {{
                            COMMON.priceWithCurrency(
                              store.lastSelectedAccount,
                              COMMON.convertToTwoDecimalDigits(
                                store.tradingAccountDetail.margin_detail
                                  .equity -
                                  store.tradingAccountDetail.margin_detail
                                    .balance
                              )
                            )
                          }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-if="store.lastSelectedAccount.account_id"
                  class="dropdown first-sec"
                >
                <div class="d-flex align-items-center roleAD">
                  <div class="holder customBtn mr-3">
                    <router-link
                      class="btn btn-secondary large"
                      to="/web-trader"
                      target="_blank"
                      >Web Trader</router-link

                    >
                    <!-- <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    SELECT WEB TRADER
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <router-link class="dropdown-item" :to="{name: 'Web Trader', params: {type: ''}}">Web Trader For MT4</router-link>
                    <router-link class="dropdown-item" :to="{name: 'Web Trader', params: {type: ''}}">Web Trader For MT5</router-link>
                  </div> -->
                  </div>
                  <div class="holder customBtn">
                    <router-link
                      class="btn btn-secondary large"
                     :to="{name : 'Manage Funds'}"
                      >Fund</router-link
                      
                    >
                  </div>
                  </div>
                </div>
              </div>
              <div class="row-sec">
                <div
                  v-if="showOpenTrades"
                  class="table-holder open-table-holder"
                >
                  <OpenTrades />
                </div>
                <div v-else class="table-holder close-table-holder">
                  <ClosedTrades />
                </div>
              </div>
              <div class="swicth-holder">
                <div class="switch">
                  <input type="checkbox" v-model="showOpenTrades" />
                  <label for="c1"><span></span></label>
                </div>
              </div>
            </div>
            <div class="sideboard f-right">
              <!-- <div class="wallet-balance">
              <div class="title-holder">
                <img src="assets/images/waller-ico.png" alt="" />
                <h5>WALLET BALANCE</h5>
              </div>
              <div class="balances-holder">
                <div class="balance">
                  <p>USD Wallet</p>
                  <p class="value">3,230.12</p>
                </div>
                <div class="balance">
                  <p>EUR Wallet</p>
                  <p class="value">2,125.34</p>
                </div>
              </div>
            </div> -->
              <div class="promotions-holder">
                <router-link to="/bonus">
                  <h3>Promotions</h3>
                  <div class="img-holder">
                    <img src="assets/images/promo-img.png" alt="" />
                  </div>
                  <div class="t-center">
                    <h3>100% <br />Bonus</h3>
                    <br />
                    <h4>Avail 100% <br />Deposit bonus</h4>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="loaderContainer" v-else>
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p>Loading initial data</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
//import AccountsDropdown from "@/components/shared/AccountsDropdown";
import ClosedTrades from "@/components/trades/ClosedTrades";
import OpenTrades from "@/components/trades/OpenTrades";
import Verify from "@/views/user/verify";

export default {
  name: "Home",
  setup() {
    const store = useMainStore();
    return { store };
  },
  components: { ClosedTrades, OpenTrades, /*AccountsDropdown,*/ Verify },
  data() {
    return {
      showOpenTrades: true,
    };
  },
  watch: {
    showOpenTrades() {
      if (!this.showOpenTrades) {
        let keys = {
          dataRequired: {
            margin_detail: false,
            open_trades: false,
            history: true,
            account_detail: false,
          },
        };
        this.store.setLastSelectedAccount(
          this.store.lastSelectedAccount,
          keys,
          true
        );
      }
    },
  },
  mounted() {
    if (
      !this.store.tradingAccounts["records"] ||
      !this.store.tradingAccounts["records"].length
    ) {
      this.store.getTradingAccounts();
    } else {
      let keys = {
        dataRequired: {
          margin_detail: true,
          open_trades: true,
          history: false,
          account_detail: false,
        },
      };
      this.store.setLastSelectedAccount(
        this.store.lastSelectedAccount,
        keys,
        true
      );
    }
    this.store.getCountriesList();

    if (
      !this.store.depositsList.records ||
      !this.store.depositsList.records.length
    ) {
      const params = {
        type: "DEPOSITS",
        page: 1,
        perPage: this.static_vars.limitPerPage,
        search: "",
        sort: [],
      };
      this.store.getTransactionsList(params, false);
    }
  },
  methods: {
    GetTradingAccountDetail(e){
      let acct = this.store.tradingAccounts.records.find(
        (i) => i.account_id == e.target.value
      );
      let keys = {
        dataRequired: {
          margin_detail: true,
          open_trades: false,
          history: false,
          account_detail: false,
        },
      };
      this.store.setLastSelectedAccount(acct, keys,false,'');
    },
    MergeAcc(acc_type,platform){
        let demoMT4 = this.store.tradingAccounts.records.filter(i => i.demo_live == acc_type && i.platform == platform).map(val => val)
        return demoMT4 || []
    },
    hasLiveAccount() {
      if (this.store.liveAccounts.length) {
        return true;
      } else {
        return false;
      }
    },
    isVerified() {
      if (this.store.user.verified_status) {
        return true;
      } else {
        return false;
      }
    },
    hasDeposit() {
      if (this.store.depositsList.records.length) {
        return true;
      } else {
        return false;
      }
    },
    showSteps() {
      if (!this.hasLiveAccount() || !this.isVerified() || !this.hasDeposit())
        return true;
      return false;
    },
  },
};
</script>
